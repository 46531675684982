<template>
	<div class="background" style="height: auto !important;">
		<div class="mat-navbar">
			<nav id="toolbar" class="mt-0 d-flex w-100">
				<div class="row no-gutters align-items-center flex-nowrap w-100">
					<div class="col">
						<div class="row no-gutters align-items-center flex-nowrap logo-digi">
							<!-- <button type="button" class="toggle-aside-button btn btn-icon hidden-md-upp" style="z-index: 4;" v-on:click="select()">
								<i class="icon icon-menu text-white"></i>
							</button> -->
							<div class="shortcuts-wrapper row no-gutters align-items-center px-0 px-sm-0"
								style="z-index: 1; padding-left: 0px !important;">
								<div class="shortcuts row no-gutters align-items-center">
									<a class="shortcut-button mx-1 app-title text-white menu-btn-hide" style="cursor: auto;"
										href="javascript:;">
										<!-- <img class="d-sm-none d-block" src="/static/images/digicollect_new_logo_ui.svg" alt="DigiAdmin" style="width: 35px; height: 30px;"> -->
										<span class="d-sm-block d-none" style="font-family: 'Poppins', sans-serif !important;">DigiCollect</span>
									</a>
								</div>
							</div>
						</div>
						<div class="row no-gutters align-items-center flex-nowrap digi-logo pointer">
							<div class="shortcuts-wrapper row no-gutters align-items-center px-0 px-sm-0"
								style="z-index: 1; padding-left: 0px !important;">
								<div class="shortcuts row no-gutters align-items-center">
									<a class="shortcut-button mx-1 app-title text-white menu-btn-hide" style="cursor: auto;"
										href="javascript:;">
										<img src="/static/images/digicollect_new_logo_ui.svg" style="width: 26px; height: 22px;">
									</a>
								</div>
								<div class="shortcuts row no-gutters align-items-center ml-2 menu" >
									<!-- <div class="dots-vertical background-dots"> -->
										<img src="/static/images/menu_icon_new_ui.png" @click="expandMenuLeft" style="width: 20px; height: 15px;">
									<!-- </div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="center-logo navbar-header nav-bar-digi">
						<router-link class="navbar-branddigi" :to="''">
							<img src="/static/images/admin_icon_white.svg" alt="DigiAdmin" style="width: 25px !important;">
							<span class="dark-logo hidden-sm-down ml-2">
								DigiAdmin
							</span>
						</router-link>
					</div>
					<div class="center-logo navbar-header align">
						<router-link class="navbar-branddigi" :to="''">
						<img src="/static/images/admin_icon_white.svg" alt="DigiAdmin" class="img-logo" style="width: 20px !important; height: 18px !important;">
							<span class="dark-logo ml-1 fs-16 fw-600" style="color: #fff;">
								DigiAdmin
							</span>
						</router-link>
					</div>
					<div class="mr-2 text-white box-shadow isp-active isp-button" :class="{'digi-red': !isp_stable}">
						ISP 
					</div>
					<div class="minimize-menu pointer" @click="menuBars('submenu')">
						<div class="align-items-center">
							<el-tooltip class="item" effect="dark" content="More Menu" placement="bottom">
								<div class="dots-vertical background-dots">
									<i class="icon icon-dots-horizontal dots-vertical fs-25 pointer" style="color: #fff;margin-top: 25px;"></i>
								</div>
							</el-tooltip>
						</div>
					</div>
					<!-- Search All Users, Employees and Companies -->
					<div class="dropdown">
						<el-tooltip class="item" effect="dark" content="Search" placement="bottom">
							<!--div class="dropdown-toggle pointer dropdownhideafter row align-items-center justify-content-center no-gutters px-2 text-white box-shadow"
								role="button"
								style="left:0px !important;height: 40px; width: 40px; padding: 5.5px 5px 5px 5.5px;background-color: #1c61c2; border-radius: 50%;"
								id="dropdownShortcutMenu" data-toggle="dropdown" aria-haspopup="true">
								<i class="icon icon-magnify searchContent"
									style="font-size: 18px;width: 18px;height: 18px;line-height: 18px;font-weight: 800; background-color: transparent !important;"></i>
							</div-->
						</el-tooltip>
						<div class="dropdown-menu dropdown-search" aria-labelledby="dropdownShortcutMenu">
							<section>
								<div class="px-3">
									<div class="">
										<div class="dropdown-search-block">
											<div class="text-muted ml-2 mt-3">
												Search Contacts, Companies, Leads, Products or Deals
											</div>
											<div class="form-group">
												<div class="input-search-rounded-box">
													<i class="input-search-icon icon-magnify" @click="clearSearchkey"
														aria-hidden="true"></i>
													<input type="text" class="form-control" autocomplete="off"
														v-model="search_key" name="search_key" @keyup="searchUser"
														placeholder="Search..." style="padding-right: 30px !important" >
													<button type="button"
														class="input-search-close icon icon-close-circle"
														@click="clearSearchkey" aria-label="Close"
														style="outline: none;">
													</button>
												</div>
											</div>
										</div>
										<!-- <div class="form-group contactGroup">
											<span class="icon icon-close-circle closeIcon text-secondary pointer"></span>
											<span class="icon icon-magnify iconsearch  searchIcon text-secondary pointer"></span>
											<input class="searchProducts" type="text" required>
										</div> -->
										<!-- <div class="py-2 productSearch"
											style="padding-left: 16px !important;padding-right: 16px !important;"
											v-if="searched_data.length">
											<div class="contact row pt-2 pointer"
												v-for="(search, index) in searched_data" :key="index">
												<div class="search-user-company-blocks" v-if="!search.company_name"
													@click="switchToProfile(search._id)">
													<div class="avatar-wrapper">
														<img :src="search.profile_pic||search.logo||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/company-logo.png'"
															class="avatar" :alt="search.full_name">
													</div>
													<div class="col px-4">
														<span class="name user-company-name h6 fs-13 text-truncate"
															:title="search.full_name">{{ search.full_name }}</span>
														<p class="mailtext text-truncate text-muted fs-13"
															:title="search.primary_email">{{ search.primary_email}}</p>
													</div>
													<div class="col-sm-auto d-flex flex-column align-items-end">
														<div class="last-message-time fs-13">In
															<span style="color:#00448b">User</span>
														</div>
													</div>
												</div>
												<div class="search-user-company-blocks" v-else
													@click="gotoCompany(search._id)">
													<div class="avatar-wrapper">
														<img :src="search.profile_pic||search.logo||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
															class="avatar" :alt="search.company_name">
													</div>
													<div class="col px-4">
														<span class="name user-company-name h6 fs-13 text-truncate"
															:title="search.company_name">{{ search.company_name }}</span>
														<p class="mailtext text-truncate text-muted fs-13"
															:title="search.primary_email">{{ search.primary_email}}</p>
													</div>
													<div class="col-sm-auto d-flex flex-column align-items-end">
														<div class="last-message-time fs-13">In
															<span style="color:#00448b">Company</span>
														</div>
													</div>
												</div>
											</div>
										</div> -->
										<div class="py-2 px-4 productSearch" v-if="!searched_data.length">
											<div class="contact row pointer text-center"
												style="color: #4e5764;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;margin-bottom: 50px;">
												<div class="pt-4 pb-15">
													Sorry We Couldn’t Find Any Matches for <span
														class="text-danger">"{{ search_key }}"</span>
												</div>
												<div class="grey-border-box" @click="goToAddNewUser()">
													Click Here to Invite User
													<!-- <button class="btn btn-secondary btn-smm ml-1 btnhide-dropdown">ADD USER</button> -->
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <footer class="searchfooter" style="background-color: #00448b !important;" v-if="searched_data.length">
									<div align="center" class="fw-600 d-block pointer searchTitle">
										Show All
									</div>
								</footer>	 -->
							</section>
						</div>
					</div>
					<!-- Notifications Dropdown -->
					<!-- <el-dropdown trigger="click" placement="bottom" class="notification pr-2">
						<span class="el-dropdown-link">
							<el-tooltip class="item" effect="dark" content="Notifications" placement="bottom">
								<div class="pointer row align-items-center no-gutters px-2 btn btn-icon text-white"
									style="left:0px !important;height: 58px;line-height: 40px;box-shadow: none !important;">
									<img src="https://cdn.digicollect.com/cdn/scmnew/images/menus/images/notification.svg" class="w-85">
									<span class="countnotification s-4">
										{{ notificationsCount }}
									</span>
								</div>
							</el-tooltip>
						</span>
						<el-dropdown-menu slot="dropdown">
							<div class="notification" style="max-width: 420px; width: 420px;">
								<div class="notificationblock" v-if="!seeRequests">
									<div class="notifyheader" style="background-color: #f1f8fc !important;">
										<div class="descriptionheader">
											<div class="description-text-header-left">
												<h5>Recent Notifications</h5>
											</div>
										</div>
									</div>
									<div class="">
										<div class="mb-4">
											<div class="py-1 px-4 notifySearch">
												<div class="row" v-if="ajaxCallinprogress">
													<div class="col-lg-12 py-5 px-3 mt-10">
														<div class="flex-container">
															<half-circle-spinner :animation-duration="1000" :size="60"
																color="#fff" />
														</div>
													</div>
												</div>
												<div v-if="!ajaxCallinprogress && notificationsList.length === 0"
													class="contact row py-4 pointer text-center"
													style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
													<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_notifications.svg"
														class="img-fluid"
														style="width: 100px;display: block;margin: 0 auto;">
													<span class="pt-3">No Notifications Found</span>
												</div>
												<div v-if="!ajaxCallinprogress">
													<div v-for="notification in notificationsList" :key="notification._id"
														@click="notificationRead(notification._id)"
														v-bind:class="{'unread': notification.status=='unread'}">
														<div class="contact row py-2 px-4 pointer m-0"
															:style="notification.is_read ? 'background-color:rgba(13, 91, 230, 0.16);' : 'background-color: #fff;'">
															<div class="avatar-wrapper">
																<img :src="notification.profile_pic||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																	class="avatar" :alt="notification.full_name">
															</div>
															<div class="col px-4">
																<span class="name h6 fs-14">
																	<span class="defaultDarkBlue"
																		@click="goToProfile(notification.sent_by)">{{notification.full_name}}</span>
																	has accepted your Connection Request
																</span>
															</div>
															<div
																class="col-sm-auto d-flex flex-column align-items-end pr-0 pl-0">
																<div class="show-message-time fs-12">
																	{{notification.created_time}}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="acceptreject" v-if="seeRequests">
									<div class="notifyheader" style="background-color: #f1f8fc !important;">
										<div class="descriptionheader">
											<div class="description-text-header-left">
												<h5>Manage Requests</h5>
											</div>
											<div class="description-text-header-right" @click="seeRequests=!seeRequests">
												Recent Notifications
											</div>
										</div>
									</div>
									<ul class="nav nav-tabs nav-fill nav-topbar-tab">
										<li class="nav-item">
											<a class="nav-link subnav-tabs" style="border-right: 1.5px solid #cdd2da;"
												href="javascript:;" :class="{'active':contactRequestTab==true}"
												@click="changeModelView('contactTab')">Connection Requests</a>
										</li>
										<li class="nav-item">
											<a class="nav-link subnav-tabs" href="javascript:;"
												:class="{'active':employeeRequestTab==true}"
												@click="changeModelView('employeeTab')">Employee Requests</a>
										</li>
									</ul>

									<div class="tab-content">
										<div class="" role="tabpanel" aria-labelledby="contact-requests-tab"
											v-if="contactRequestTab">
											<div class="">
												<div class="mb-4">
													<div class="py-1 px-4 notifySearch">
														<div class="row" v-if="ajaxCallinprogress">
															<div class="col-lg-12 py-5 px-3 mt-10">
																<div class="flex-container">
																	<half-circle-spinner :animation-duration="1000"
																		:size="60" color="#fff" />
																</div>
															</div>
														</div>
														<div v-if="!ajaxCallinprogress && connectionsList.length === 0"
															class="contact row py-4 pointer text-center"
															style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
															<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_notifications.svg"
																class="img-fluid"
																style="width: 100px;display: block;margin: 0 auto;">
															<span class="pt-3">No Notifications Found</span>
														</div>
														<div v-if="!ajaxCallinprogress">
															<div v-for="connection in connectionsList"
																:key="connection._id">
																<div class="contact row py-1 px-4 pointer mb-0"
																	:style="connection.is_read ? 'background-color:rgba(13, 91, 230, 0.16);' : 'background-color: #fff;'">
																	<div class="avatar-wrapper">
																		<img :src="connection.profile_pic||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																			class="avatar" :alt="connection.full_name">
																	</div>
																	<div class="col pl-4 pr-2">
																		<span class="name h6 fs-14">
																			Request from <span class="defaultDarkBlue"
																				@click="goToProfile(connection.sent_by)"
																				style="color: #00448b !important;">{{connection.full_name}}</span>
																			to join their network
																		</span>
																		<div class="name h6 fs-14 d-flex align-items-start">
																			Message:<span
																				style="color: #414141 !important; margin-left: 10px;">{{connection.message}}</span>
																		</div>
																		<p class="last-message text-truncate py-1 mb-0">
																			<button
																				class="btn btn-new-danger btn-wide mx-4 mb-1"
																				v-on:click.once="changeStatus('ignore',connection._id,connection.sent_by)">Reject</button>
																			<button
																				class="btn btn-new-green btn-wide ml-1 mr-4 mb-1"
																				v-on:click.once="changeStatus('connected',connection._id,connection.sent_by)">Accept</button>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="" role="tabpanel" aria-labelledby="employee-requests-tab"
											v-if="employeeRequestTab">
											<div class="px-4">
												<div class="mb-4">
													<div class="py-1 px-4 notifySearch">
														<div class="row" v-if="ajaxCallinprogress">
															<div class="col-lg-12 py-5 px-3 mt-10">
																<div class="flex-container">
																	<half-circle-spinner :animation-duration="1000"
																		:size="60" color="#fff" />
																</div>
															</div>
														</div>
														<div v-if="!ajaxCallinprogress && employeeDataCount.length === 0"
															class="contact row py-4 pointer text-center"
															style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
															<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_connection.svg"
																class="img-fluid"
																style="width: 100px;display: block;margin: 0 auto;">
															<span class="pt-3">No Requests Found</span>
														</div>
														<div v-if="!ajaxCallinprogress">
															<div class="contact row py-1 pointer"
																v-for="employeeconnection in employeeDataCount"
																:key="employeeconnection._id">
																<div class="avatar-wrapper">
																	<img :src="employeeconnection.company.logo||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																		class="avatar"
																		:alt="employeeconnection.company.company_name">
																</div>
																<div class="col pl-4 pr-2">
																	<span class="name h6 fs-14">
																		<span
																			class="defaultDarkBlue">{{employeeconnection.company.company_name}}</span><br>
																		invited by <span
																			class="text-secondary">{{employeeconnection.invited_by.full_name}}</span>
																	</span>
																	<p class="last-message text-truncate py-1 mb-0">
																		<a class="btn btn-new-primary btn-smm ml-1 mr-4 mb-1"
																			v-on:click.once="changeEmployeeStatus('active',employeeconnection._id)">Accept</a>
																		<a class="btn btn-new-darkdanger btn-smm mx-4 mb-1"
																			v-on:click.once="changeEmployeeStatus('ignore',employeeconnection._id)">Reject</a>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-dropdown-menu>
					</el-dropdown> -->
					<!-- Products Dropdown -->
					<el-dropdown trigger="click" placement="bottom" class="products pr-2">
						<span class="el-dropdown-link">
							<el-tooltip class="item" effect="dark" content="Products" placement="bottom">
								<div class="pointer row align-items-center justify-content-center no-gutters px-2 text-white box-shadow"
									style="left:0px !important;height: 40px; width: 40px;background-color: #1c61c2; border-radius: 50%;">
										<img src="https://cdn.digicollect.com/cdn/scmnew/images/menus/images/products.svg" class="w-85">
								</div>
							</el-tooltip>
						</span>
						<el-dropdown-menu slot="dropdown">
							<div v-if="productsList.length > 0" class="dropdown-apps uk-grid uk-grid-width-medium-1-3 uk-text-center d-flex align-items-center flex-wrap">
								<a v-for="(product,index) in productsList" :key="index" :href="product.product_name == 'DigiAdmin' ? 'javascript:' : product.public_url" :target="product.product_name == 'DigiAdmin' ? '' : '_blank'"
									class="logoblock px-2">
									<div class="product-placement">
										<i v-if="product.product_name == 'Accounting'" class="logo-accounting-bookeeping material-icons md-36 md-color-orange-600"></i>
									</div>
									<div>
										<i v-if="product.product_name == 'Chat'" class="logo-chat material-icons md-36 md-color-light-green-600"></i>
									</div>
									<div>
										<i v-if="product.product_name == 'DigiHRMS'" class="logo-hrms material-icons md-36 md-color-blue-600 pl-5" > </i>
									</div>
									<div>
										<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/admin.png" v-if="product.product_name == 'DigiAdmin'" class="logo-cloud material-icons md-36 md-color-orange-600 ml-3" style="width: 28px; height: 28px;"/>
									</div>
									<div class="pl-2">
										<img src="https://cdn.digicollect.com/cdn/boardx/static/images/login_logo.png" v-if="product.product_name == 'BoardX'" class="logo-cloud material-icons md-36 md-color-orange-600 pl-2" style="width: 28px; height: 28px;"/>
									</div>
									<div>
										<i v-if="product.product_name == 'CRM'" class="logo-crm material-icons md-36 md-color-light-green-600"></i>
									</div>
									<div>
										<i v-if="product.product_name == 'DigiPOS'" class="logo-pos material-icons md-36 md-color-blue-600 pl-2" style="font-size: 18px !important;"></i>
									</div>
									<div class="product-placement">
										<i v-if="product.product_name == 'SCM'" class="logo-scm material-icons md-36 md-color-blue-600 pl-2"></i>
									</div>
									<div class="mr-1 product-placement">
										<i v-if="product.product_name == 'Forms'" class="logo-forms material-icons md-36 md-color-cyan-600"></i>
									</div>
									<div class="mr-1">
										<i v-if="product.product_name == 'DigiHelp'" class="logo-help-desk material-icons md-36 md-color-blue-600"></i>
									</div>
									<div class="">
										<i v-if="product.product_name == 'DigiExpense'" class="logo-expence-management material-icons md-36 md-color-blue-600" style="font-size: 22px !important;margin-left: -5px !important;"></i>
									</div>
									<span v-if="product.product_name == 'Accounting'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiAccounting</span>
									<span v-if="product.product_name == 'DigiAdmin'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiAdmin</span>
									<span v-if="product.product_name == 'Chat'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiChat</span>
									<span v-if="product.product_name == 'DigiHRMS'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiHRMS</span>
									<span v-if="product.product_name == 'CRM'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiCRM</span>
									<span v-if="product.product_name == 'DigiPOS'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiPOS</span>
									<span v-if="product.product_name == 'SCM'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiSCM</span>
									<span v-if="product.product_name == 'Forms'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiForms</span>
									<span v-if="product.product_name == 'DigiHelp'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiHelp</span>
									<span v-if="product.product_name == 'DigiExpense'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500" style="margin-left: 6px !important;">DigiExpense</span>
									<span v-if="product.product_name == 'BoardX'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500 pl-2">BoardX</span>
									<hr>
									<!-- <i v-if="product.product_name == 'Accounting'"
										class="logo-accounting-bookeeping material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'Chat'"
										class="logo-chat material-icons md-36 md-color-light-green-600"></i>
									<i v-if="product.product_name == 'Cloud'"
										class="logo-cloud material-icons md-36 md-color-orange-600"
										style="width: 46px;"></i>
									<i v-if="product.product_name == 'Contract Management'"
										class="logo-contract-management material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'DigiAds'"
										class="logo-ads material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'DigiPOS'"
										class="logo-pos material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'SCM'"
										class="logo-scm material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'Forms'"
										class="logo-forms material-icons md-36 md-color-cyan-600"></i>
									<i v-if="product.product_name == 'DigiPlay'"
										class="logo-play material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'Expense Management'"
										class="logo-expence-management material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'FlatViz'"
										class="logo-flatviz material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'DigiHelp'"
										class="logo-help-desk material-icons md-36 md-color-blue-600"></i>
									<i v-if="product.product_name == 'HRMS'"
										class="logo-hrms material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'IMS'"
										class="logo-ims material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'Marketplace'"
										class="logo-marketplace material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'MFI'"
										class="logo-mfi material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'PerfMon'"
										class="logo-monitoring material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'SecureMon'"
										class="logo-monitoring material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'SiteViz'"
										class="logo-siteviz material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'CRM'"
										class="logo-crm material-icons md-36 md-color-orange-600"></i>
									<i v-if="product.product_name == 'DigiAdmin'"
										class="logo-cloud material-icons md-36 md-color-orange-600"></i>
                    				<i v-if="product.product_name == 'DigiExpense'" 
										class="logo-expence-management material-icons md-36 md-color-blue-600" style="font-size: 36px !important;"></i>
									<span class="uk-text-muted uk-display-block" style="padding-left: 10px;">{{product.product_name}}</span> -->
								</a>
							</div>
							<div v-else class="fs-14 px-5 py-6 fw-600">
								No products Subscribed
							</div>
						</el-dropdown-menu>
					</el-dropdown>

					<div class="col-auto">
						<div class="row no-gutters align-items-center justify-content-end">
							<el-dropdown trigger="click" placement="bottom" class="time">
								<span class="el-dropdown-link">
									<div class="timezone-button products-button pointer">
										<div class="w-100">
											<span class="text-white text-center d-block" v-if="selectedTimeZone==''">
												{{timeDefault}}
											</span>
											<span class="text-white text-center d-block" v-if="selectedTimeZone=='America/New_York'">
												{{timeNewYork}}
											</span>
											<span class="text-white text-center d-block" v-if="selectedTimeZone=='Europe/London'">
												{{timeLondon}}
											</span>
											<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Kolkata'">
												{{timeKolkata}}
											</span>
											<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Singapore'">
												{{timeSingapore}} SGT
											</span>
											<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Yangon'">
												{{timeYangon}} MMT
											</span>
										</div>
									</div>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item >
										<div class="row no-gutters align-items-center flex-nowrap">
											<span class="px-3"
												v-on:click=' guess = false, newyork = true, london=false, kolkata=false, singapore=false, yangon=false, selectedTimeZone = "America/New_York" '>{{timeNewYork}}</span>
										</div>
									</el-dropdown-item>
									<el-dropdown-item >
										<div class="row no-gutters align-items-center flex-nowrap">
											<span class="px-3"
												v-on:click=' guess = false, newyork = false, london=true, kolkata=false, singapore=false, yangon=false, selectedTimeZone = "Europe/London" '>{{timeLondon}}</span>
										</div>
									</el-dropdown-item>
									<el-dropdown-item >
										<div class="row no-gutters align-items-center flex-nowrap">
											<span class="px-3"
												v-on:click=' guess = false, newyork = false, london=false, kolkata=true, singapore=false, yangon=false, selectedTimeZone = "Asia/Kolkata" '>{{timeKolkata}}</span>
										</div>
									</el-dropdown-item>
									<el-dropdown-item >
										<div class="row no-gutters align-items-center flex-nowrap">
											<span class="px-3"
												v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=true, yangon=false, selectedTimeZone = "Asia/Singapore" '>{{timeSingapore}}
												SGT</span>
										</div>
									</el-dropdown-item>
									<el-dropdown-item >
										<div class="row no-gutters align-items-center flex-nowrap">
											<span class="px-3"
												v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=false, yangon=true, selectedTimeZone = "Asia/Yangon" '>{{timeYangon}}
												MMT</span>
										</div>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<!-- <div class="toolbar-separator text-white"></div> -->

							<!-- Logout and Company Account List and Switching Between Personal and Company View Only Happens in Server Because to Set Token -->

							<div class="user-menu-button" >
								<div class="pl-3 pr-4 position-relative">
									<span class="pointer" @click="showHideCompSwitchDropdown">
										<div class="d-flex align-items-center" style="background-color: #fff; height: 35px; border-radius: 5px;">

											<span class="username mx-3 d-none d-md-block text-truncate"
												style="font-size: 14px;padding-top:2px;max-width:100px;color: #00448b;"
												:title="loggedInUser.full_name">
													{{loggedInUser.full_name}}
											</span>
											<div class="avatar-wrapper pr-3">
												<img class="avatar"
													:src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" />
											</div>
											<!-- <i class="icon icon-chevron-down text-white"></i> -->
										</div>
									</span>
									<div v-if="show_hide_comp_switch_dropdown" @click="onBlurHideCompSwitch" class="switch-company-backdrop">
									</div>
									<div v-if="show_hide_comp_switch_dropdown" class="admin-width">
										<div v-if="user_company.organization_name">
											<div style="text-align: center;" class="my-3">
												<span class="fw-400 fs-12"> This account is managed by {{user_company.organization_name}}</span>
											</div>
										</div>
										<div class="dropdown-width-responsive" style="overflow: hidden;">
											<div class="pt-3">
												<hr>
												<div class="d-flex align-items-center w-100 pt-2 pb-0">
													<div class="switch-user-prpfile ml-4 mt-2">
														<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="70" height="70" />
														<div class="col-1 hide-on-mob-screen">
															<div class="pointer btn-size">
																<button class="btn btn-secondary btn-smm logout-btn-color mt-8" @click="logout()">
																	Logout
																</button>
															</div>
															<!-- <div class="settings-icon pointer setting-align" @click="goToSettings">
																<img width="18" src="https://testcrm.digicollect.com/static/images/images-new-theme/icons/settings-new-right.svg"/>
															</div> -->
														</div>
													</div>
													<div class="w-100 pl-2">
														<div class="text-secondary fs-14 pl-2 pr-4">
															<span class="fw-800 text-capitalize"
																:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
															<span class="ml-1 text-gray fw-600 text-capitalize" style="color: #3f3f3f;">({{loggedInUser.user_role.replaceAll('_',' ')}})</span>
														</div>
														<div class="d-flex main-dropdown justify-content-between pointer pl-2 pr-4 background-default">
															<div class="mt-0">
																<!-- <h1 v-if="user_company.organization_name" class="fs-14 text-gray mb-0 text-capitalize">{{user_company.organization_name}}</h1> -->
																<h1 v-if="user_company.company_name" class="fs-14 text-gray mb-0 text-secondary text-capitalize">{{user_company.company_name || "-"}} <span class="text-secondary" >(Selected)</span></h1>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="show-on-mob-screen text-right mx-3">
												<button class="btn btn-secondary btn-smm logout-btn-color mt-8" @click="logout()">
													Logout
												</button>
											</div>
											<hr class="mt-8">
											<div v-if="org_company_list_loading">
												<half-circle-spinner :animation-duration="1000" :size="30" color="#00448b" style="margin: 0 auto;" />
											</div>
											<template v-else>
												<div @scroll="scrollGetOrganizationAndComp" v-if="comp_data.length > 0" class="mt-2" :class="{'overflow-height':comp_data.length > 0}">
													<div v-for="(comp, comp_index) in comp_data" :key="comp_index">
														<!-- <hr class="mt-5"> -->
														<template v-if="loggedInUser.user_has == 'organization'">
															<div v-if="comp.company_list">
																<div class="d-flex align-items-center w-100 py-2">
																	<div class="w-100 px-2">
																		<div class="d-flex align-items-center main-dropdown subcompany-dropdown justify-content-between pointer pl-2 pr-4" :class="{'green-active-org':comp_index == 0,'br-t-r-5':comp.org_active}" @click="compActive(comp_index)">
																			<div class="d-flex align-items-center">
																				<avatar
																					class="navbar-avatar navbar-avatar-org-name"
																					:username="comp.organization_name.replace(/ .*/,'')"
																					:src="''"
																					:size="40"
																					:rounded="false"
																					:color="comp_index == 0 ? '#008b29' : '#ffffff'"
																					:background-color="comp_index == 0 ? '#ffffff' : '#707070'">
																				</avatar>
																				<div class="mt-0 ml-3">
																					<h1 class="fs-14 mb-0 text-capitalize fw-500" :class="{'text-white':comp_index == 0, 'text-gray':comp_index != 0}">{{comp.organization_name}}</h1>
																				</div>
																			</div>
																			<div v-if="comp.company_list.length > 0" class="mob-left-padding-drop">
																				<i style="font-size: 25px;" class="icon el-icon-arrow-up fw-700 org-icon-rotate-transition" :class="{'org-icon-rotate-down':!comp.org_active,'text-white':comp_index == 0, 'dc-text-black':comp_index != 0}"></i>
																				<!-- <i style="font-size: 25px;" v-else class="icon el-icon-arrow-down fw-700" :class="{'text-white':comp_index == 0, 'dc-text-black':comp_index != 0}"></i> -->
																			</div>
																		</div>
																	</div>
																</div>
																<div v-if="comp.org_active" class="px-2">
																	<div v-for="(company, company_index) in comp.company_list" :key="company_index" class="d-flex align-items-center w-100 company-list list-dropdown" v-show="!company.selected">
																		<div v-if="company.logo">
																			<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="company.logo" width="30" height="30" />
																		</div>
																		<div v-else>
																			<avatar
																				class="navbar-avatar navbar-avatar-comp-name"
																				:username="company.company_name.replace(/ .*/,'')"
																				:src="''"
																				:size="30"
																				:rounded="false"
																				:color="company.selected ? '#ffffff' : '#ffffff'"
																				:background-color="company.selected ? '#008b29' : '#707070'">
																			</avatar>
																		</div>
																		<div class="w-100 pl-4">
																			<div class="d-flex align-items-center justify-content-between">
																				<div>
																					<h1 class="fs-14 mb-0 text-capitalize fw-500" :class="{'text-success':company.selected, 'not-selected':!company.selected}">{{company.company_name}}</h1>
																				</div>
																				<!-- :class="{'cursor-not-allowed': !company.can_company_switch}" -->
																				<div v-if="!company.selected">
																					<h1 class="fs-14 text-secondary mb-0 pointer fw-500" @click="switchNewCompany(company.company_id, company.can_company_switch)">Switch</h1>
																				</div>
																				<div v-else class="pr-3">
																					<i class="icon icon-check-circle text-success"></i>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</template>
													</div>
													<template v-if="loggedInUser.user_has != 'organization'">
														<div v-if="comp_data[0].company_list != null">
															<template>
																<div class="d-flex align-items-center w-100 company-list list-dropdown" style="background-color: #fff;height: 48px !important;" v-for="(company, company_index) in comp_data[0].company_list" :key="company_index">
																	<div v-if="company.logo" :class="{'back-color':company.selected}" style="border-top-left-radius: 2px;border-bottom-left-radius: 2px">
																		<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="company.logo" width="20" height="20" />
																	</div>
																	<div v-else style="border-top-left-radius: 2px;border-bottom-left-radius: 2px">
																		<avatar
																			class="navbar-avatar navbar-avatar-comp-name"
																			:username="company.company_name.replace(/ .*/,'')"
																			:src="''"
																			:size="30"
																			:rounded="false"
																			:color="company.selected ? '#ffffff' : '#ffffff'"
																			:background-color="company.selected ? '#008b29' : '#707070'">
																		</avatar>
																	</div>
																	<div class="w-100 pl-2" :class="{'back-color':company.selected}" style="border-top-right-radius: 2px;border-bottom-right-radius: 2px">
																		<div class="d-flex align-items-center justify-content-between">
																			<div>
																				<h1 class="fs-14 mb-0 text-capitalize fw-500" :class="{'text-success':company.selected, 'not-selected':!company.selected}">{{company.company_name}}</h1>
																			</div>
																			<!-- :class="{'cursor-not-allowed': !company.can_company_switch}" -->
																			<div v-if="!company.selected">
																				<h1 class="fs-14 text-secondary mb-0 pointer fw-500" @click="switchNewCompany(company.company_id, company.can_company_switch)">Switch</h1>
																			</div>
																			<div v-else class="pr-3">
																				<i class="icon icon-check-circle text-success"></i>
																			</div>
																		</div>
																	</div>
																</div>
															</template>
														</div>
														<template v-else>
															<div class="d-flex align-items-center w-100 company-list list-dropdown" style="background-color: #fff;height: 48px !important;" v-for="(company, company_index) in comp_data" :key="company_index">
																<div v-if="company.logo" :class="{'back-color':company.selected}" style="border-top-left-radius: 2px;border-bottom-left-radius: 2px">
																	<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="company.logo" width="20" height="20" />
																</div>
																<div v-else style="border-top-left-radius: 2px;border-bottom-left-radius: 2px">
																	<avatar
																		class="navbar-avatar navbar-avatar-comp-name"
																		:username="company.company_name.replace(/ .*/,'')"
																		:src="''"
																		:size="30"
																		:rounded="false"
																		:color="company.selected ? '#ffffff' : '#ffffff'"
																		:background-color="company.selected ? '#008b29' : '#707070'">
																	</avatar>
																</div>
																<div class="w-100 pl-2" :class="{'back-color':company.selected}" style="border-top-right-radius: 2px;border-bottom-right-radius: 2px">
																	<div class="d-flex align-items-center justify-content-between">
																		<div>
																			<h1 class="fs-14 mb-0 text-capitalize fw-500" :class="{'text-success':company.selected, 'not-selected':!company.selected}">{{company.company_name}}</h1>
																		</div>
																		<!-- :class="{'cursor-not-allowed': !company.can_company_switch}" -->
																		<div v-if="!company.selected">
																			<h1 class="fs-14 text-secondary mb-0 pointer fw-500" @click="switchNewCompany(company.company_id, company.can_company_switch)">Switch</h1>
																		</div>
																		<div v-else class="pr-3">
																			<i class="icon icon-check-circle text-success"></i>
																		</div>
																	</div>
																</div>
															</div>
														</template>
													</template>
													<!-- <template v-else>
														<div class="d-flex align-items-center w-100 company-list list-dropdown">
															<div v-if="comp.logo">
																<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="comp.logo" width="30" height="30" />
															</div>
															<div v-else>
																<avatar
																	class="navbar-avatar navbar-avatar-comp-name"
																	:username="comp.company_name.replace(/ .*/,'')"
																	:src="''"
																	:size="30"
																	:rounded="false"
																	:color="comp.selected ? '#ffffff' : '#ffffff'"
																	:background-color="comp.selected ? '#008b29' : '#707070'">
																</avatar>
															</div>
															<div class="w-100 pl-4">
																<div class="d-flex align-items-center justify-content-between">
																	<div>
																		<h1 class="fs-14 mb-0 text-capitalize fw-500" :class="{'text-success':comp.selected, 'not-selected':!comp.selected}">{{comp.company_name}}</h1>
																	</div>
																	<div v-if="!comp.selected" :class="{'cursor-not-allowed': !comp.can_company_switch}">
																		<h1 class="fs-14 text-secondary mb-0 pointer fw-500" :class="{'pointer-events-none': !comp.can_company_switch}" @click="switchNewCompany(comp.company_id, comp.can_company_switch)">Switch</h1>
																	</div>
																	<div v-else class="pr-3">
																		<i class="icon icon-check-circle text-success"></i>
																	</div>
																</div>
															</div>
														</div>
													</template> -->
												<!-- </div> -->
											</div>
											</template>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="user-menu-button">
								<el-dropdown class="pl-3 pr-2" trigger="click">
									<span class="el-dropdown-link">
										<div class="d-flex align-items-center">
											<div class="avatar-wrapper" v-if="isBusinessAccount">
												<img class="avatar"
													:src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" />
											</div>
											<div class="avatar-wrapper" v-else>
												<img class="avatar"
													:src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" />
											</div>
											<span class="username mx-3 d-none d-md-block text-white text-truncate"
												style="font-size: 14px;padding-top:2px;max-width:100px;"
												title="User">
												<span v-if="isBusinessAccount && selectedCompany.full_name" class="fw-800"
													:title="selectedCompany.full_name">{{selectedCompany.full_name}}</span>
												<span v-else class="fw-800"
													:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
											</span>
											<i class="icon icon-chevron-down text-white"></i>
										</div>
									</span>
									<el-dropdown-menu slot="dropdown">
										<div style="width: 400px;">
											<div class="position-absolute settings-icon pointer" @click="goToSettings">
												<img src="/static/images/settings-white.svg" />
											</div>
											<div v-if="comp_data.length > 0" class="mt-13" :class="{'overflow-height':comp_data.length > 0}" style="overflow: auto;">
												<div class="d-flex align-items-center w-100 py-2">
													<div  class="switch-user-prpfile ml-4">
														<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="75" height="75" />
													</div>
													<div class="w-100">
														<div  class="text-secondary fs-14 px-4">
															<span v-if="isBusinessAccount && selectedCompany.full_name" class="fw-800 text-capitalize"
																:title="selectedCompany.full_name">{{selectedCompany.full_name}}</span>
															<span v-else class="fw-800 text-capitalize"
																:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
															<span class="ml-1 text-gray text-capitalize">({{loggedInUser.user_role}})</span>
														</div>
														<div class="d-flex align-items-center justify-content-between pointer px-4" @click="compActive(comp_index)">
															<div class="mt-1">
																<h1 class="fs-14 text-gray mb-0 text-capitalize text-data ">{{user_company.organization_name}}</h1>
																<h1  class="fs-14 text-gray mb-0 text-secondary text-capitalize">{{user_company.company_name || "-"}} <span class="text-secondary" >(Default)</span></h1>
															</div> -->

															<!--div v-if="comp.company_list.length > 0">
																<i style="font-size: 25px;" class="icon el-icon-arrow-down fw-700 dc-text-black"></i>
															</!--div-->
														<!-- </div>
													</div>
												</div>
												<div v-for="(comp, comp_index) in comp_data" :key="comp_index">
													<hr>
													<div v-if="comp.company_list">
														<div class="d-flex align-items-center w-100 py-2"> -->

															<!--div v-if="comp_index === 0" class="switch-user-prpfile ml-4">
																<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="75" height="75" />
															</!--div-->
															<!-- <div class="w-100"> -->
																<!--div v-if="comp_index === 0" class="text-secondary fs-14 px-4">
																	<span v-if="isBusinessAccount && selectedCompany.full_name" class="fw-800 text-capitalize"
																		:title="selectedCompany.full_name">{{selectedCompany.full_name}}</span>
																	<span v-else class="fw-800 text-capitalize"
																		:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
																	<span class="ml-1 text-gray text-capitalize">({{loggedInUser.user_role}})</span>
																</!--div-->

																<!-- <div class="d-flex align-items-center justify-content-between pointer px-4" @click="compActive(comp_index)">
																	<div class="mt-1">
																		<h1 class="fs-14 text-gray mb-0 text-capitalize text-data ">{{comp.organization_name}}</h1>
																		<h1 v-show="company.selected" v-for="(company, company_index) in comp.company_list" :key="company_index" class="fs-14 text-gray mb-0 text-secondary text-capitalize">{{company.company_name || "-"}} <span class="text-secondary" v-if="company.selected">(Default)</span></h1>
																	</div>
																	<div v-if="comp.company_list.length > 0">
																		<i style="font-size: 25px;" class="icon el-icon-arrow-down fw-700 dc-text-black"></i>
																	</div>
																</div>
															</div>
														</div>
														<div class="" v-if="comp_active && comp_index === given_comp_index">
															<div v-for="(company, company_index) in comp.company_list" :key="company_index" class="d-flex align-items-center w-100 py-2 mt-2 px-4 company-list">
																<div>
																	<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="company.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="50" height="50" />
																</div>
																<div class="w-100 pl-4">
																	<div class="d-flex align-items-center justify-content-between">
																		<div>
																			<h1 class="fs-14 text-secondary mb-0 text-capitalize text-data">{{company.company_name}}</h1>
																			<h1 v-if="company.selected" class="fs-14 text-secondary mb-0 text-capitalize">(Default)</h1>
																		</div>
																		<div>
																			<h1 v-if="!company.selected" class="fs-14 text-secondary mb-0 pointer" @click="switchNewCompany(company.company_id)">Switch</h1>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div v-else class="d-flex align-items-center w-100 px-4 py-2"> -->
														<!--div v-if="comp_index === 0" class="switch-user-prpfile">
															<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="75" height="75" />
														</!--div-->
														<!-- <div class="w-100 pl-4"> -->
															<!--div v-if="comp_index === 0" class="text-secondary fs-14">
																<span v-if="isBusinessAccount && selectedCompany.full_name" class="fw-800 text-capitalize"
																	:title="selectedCompany.full_name">{{selectedCompany.full_name}}</span>
																<span v-else class="fw-800 text-capitalize"
																	:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
																<span class="ml-1 text-gray text-capitalize">({{loggedInUser.user_role}})</span>
															</!--div-->
															<!-- <div class="d-flex align-items-center justify-content-between pointer" >
																<div class="mt-1">
																	<h1 class="fs-14 text-secondary mb-0 text-capitalize">{{comp.company_name}} <span v-if="comp.selected">(Default)</span></h1>
																</div>
																<div>
																	<h1 v-if="!comp.selected" class="fs-14 text-secondary mb-0 pointer" @click="switchNewCompany(comp.company_id)">Switch</h1>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else class="mt-13" :class="{'overflow-height':comp_data.length > 0}" style="overflow: auto;">
												<div>
													<hr>
													<div>
														<div class="d-flex align-items-center w-100 py-2">
															<div class="switch-user-prpfile ml-4">
																<img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="loggedInUser.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="75" height="75" />
															</div>
															<div class="w-100">
																<div class="text-secondary fs-14 px-4">
																	<span v-if="isBusinessAccount && selectedCompany.full_name" class="fw-800 text-capitalize"
																		:title="selectedCompany.full_name">{{selectedCompany.full_name}}</span>
																	<span v-else class="fw-800 text-capitalize"
																		:title="loggedInUser.full_name">{{loggedInUser.full_name}}</span>
																	<span class="ml-1 text-gray text-capitalize">({{loggedInUser.user_role}})</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<footer class="profilefooter position-relative pt-2" style="background-color: transparent !important;">
												<div class="fw-600 d-inline-block pointer searchTitle pull-right mr-3">
													<button class="btn btn-new-primary btn-logout" @click="logout()">
														Logout
													</button>
												</div>
											</footer>
										</div>
									</el-dropdown-menu>
								</el-dropdown>
							</div> -->
						</div>
					</div>
				</div>
			</nav>
			<div v-if="minimizeMenu" class="user-menu-align">
				<div class="d-flex submenu-align">
					<div class="list-submenu">
						<div class="mr-2 text-white box-shadow isp-active" :class="{'digi-red': !isp_stable}">
							ISP 
						</div>
					</div>
					<div class="list-submenu">
						<!-- Notifications Dropdown -->
						<el-dropdown trigger="click" placement="bottom" class="notificn">
							<span class="el-dropdown-link">
								<el-tooltip class="item" effect="dark" content="Notifications" placement="bottom">
									<div class="pointer row align-items-center no-gutters px-2 btn btn-icon text-white"
										style="left:0px !important;height: 58px;line-height: 40px;box-shadow: none !important;">
										<img src="https://cdn.digicollect.com/cdn/scmnew/images/menus/images/notification.svg" style="width: 30px;">
										<span class="countnotification s-4">
											{{ notificationsCount }}
										</span>
									</div>
								</el-tooltip>
							</span>
							<el-dropdown-menu slot="dropdown">
								<div class="notification-dropdown">
									<div class="notificationblock" v-if="!seeRequests">
										<div class="notifyheader" style="background-color: #f1f8fc !important;">
											<div class="descriptionheader">
												<div class="description-text-header-left">
													<h5>Recent Notifications</h5>
												</div>
											</div>
										</div>
										<div class="">
											<div class="mb-4">
												<div class="py-1 px-4 notifySearch">
													<div class="row" v-if="ajaxCallinprogress">
														<div class="col-lg-12 py-5 px-3 mt-10">
															<div class="flex-container">
																<half-circle-spinner :animation-duration="1000" :size="60"
																	color="#fff" />
															</div>
														</div>
													</div>
													<div v-if="!ajaxCallinprogress && notificationsList.length === 0"
														class="contact row py-4 pointer text-center"
														style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
														<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_notifications.svg"
															class="img-fluid"
															style="width: 100px;display: block;margin: 0 auto;">
														<span class="pt-3">No Notifications Found</span>
													</div>
													<div v-if="!ajaxCallinprogress">
														<div v-for="notification in notificationsList" :key="notification._id"
															@click="notificationRead(notification._id)"
															v-bind:class="{'unread': notification.status=='unread'}">
															<div class="contact row py-2 px-4 pointer m-0"
																:style="notification.is_read ? 'background-color:rgba(13, 91, 230, 0.16);' : 'background-color: #fff;'">
																<div class="avatar-wrapper">
																	<img :src="notification.profile_pic||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																		class="avatar" :alt="notification.full_name">
																</div>
																<div class="col px-4">
																	<span class="name h6 fs-14">
																		<span class="defaultDarkBlue"
																			@click="goToProfile(notification.sent_by)">{{notification.full_name}}</span>
																		has accepted your Connection Request
																	</span>
																</div>
																<div
																	class="col-sm-auto d-flex flex-column align-items-end pr-0 pl-0">
																	<div class="show-message-time fs-12">
																		{{notification.created_time}}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="acceptreject" v-if="seeRequests">
										<div class="notifyheader" style="background-color: #f1f8fc !important;">
											<div class="descriptionheader">
												<div class="description-text-header-left">
													<h5>Manage Requests</h5>
												</div>
												<div class="description-text-header-right" @click="seeRequests=!seeRequests">
													Recent Notifications
												</div>
											</div>
										</div>
										<ul class="nav nav-tabs nav-fill nav-topbar-tab">
											<li class="nav-item">
												<a class="nav-link subnav-tabs" style="border-right: 1.5px solid #cdd2da;"
													href="javascript:;" :class="{'active':contactRequestTab==true}"
													@click="changeModelView('contactTab')">Connection Requests</a>
											</li>
											<li class="nav-item">
												<a class="nav-link subnav-tabs" href="javascript:;"
													:class="{'active':employeeRequestTab==true}"
													@click="changeModelView('employeeTab')">Employee Requests</a>
											</li>
										</ul>

										<div class="tab-content">
											<div class="" role="tabpanel" aria-labelledby="contact-requests-tab"
												v-if="contactRequestTab">
												<div class="">
													<div class="mb-4">
														<div class="py-1 px-4 notifySearch">
															<div class="row" v-if="ajaxCallinprogress">
																<div class="col-lg-12 py-5 px-3 mt-10">
																	<div class="flex-container">
																		<half-circle-spinner :animation-duration="1000"
																			:size="60" color="#fff" />
																	</div>
																</div>
															</div>
															<div v-if="!ajaxCallinprogress && connectionsList.length === 0"
																class="contact row py-4 pointer text-center"
																style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
																<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_notifications.svg"
																	class="img-fluid"
																	style="width: 100px;display: block;margin: 0 auto;">
																<span class="pt-3">No Notifications Found</span>
															</div>
															<div v-if="!ajaxCallinprogress">
																<div v-for="connection in connectionsList"
																	:key="connection._id">
																	<div class="contact row py-1 px-4 pointer mb-0"
																		:style="connection.is_read ? 'background-color:rgba(13, 91, 230, 0.16);' : 'background-color: #fff;'">
																		<div class="avatar-wrapper">
																			<img :src="connection.profile_pic||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																				class="avatar" :alt="connection.full_name">
																		</div>
																		<div class="col pl-4 pr-2">
																			<span class="name h6 fs-14">
																				Request from <span class="defaultDarkBlue"
																					@click="goToProfile(connection.sent_by)"
																					style="color: #00448b !important;">{{connection.full_name}}</span>
																				to join their network
																			</span>
																			<div class="name h6 fs-14 d-flex align-items-start">
																				Message:<span
																					style="color: #414141 !important; margin-left: 10px;">{{connection.message}}</span>
																			</div>
																			<p class="last-message text-truncate py-1 mb-0">
																				<button
																					class="btn btn-new-danger btn-wide mx-4 mb-1"
																					v-on:click.once="changeStatus('ignore',connection._id,connection.sent_by)">Reject</button>
																				<button
																					class="btn btn-new-green btn-wide ml-1 mr-4 mb-1"
																					v-on:click.once="changeStatus('connected',connection._id,connection.sent_by)">Accept</button>
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="" role="tabpanel" aria-labelledby="employee-requests-tab"
												v-if="employeeRequestTab">
												<div class="px-4">
													<div class="mb-4">
														<div class="py-1 px-4 notifySearch">
															<div class="row" v-if="ajaxCallinprogress">
																<div class="col-lg-12 py-5 px-3 mt-10">
																	<div class="flex-container">
																		<half-circle-spinner :animation-duration="1000"
																			:size="60" color="#fff" />
																	</div>
																</div>
															</div>
															<div v-if="!ajaxCallinprogress && employeeDataCount.length === 0"
																class="contact row py-4 pointer text-center"
																style="color: #00448b;font-size: 15px;font-weight: 600;margin: 0 auto;display: block;">
																<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/no_connection.svg"
																	class="img-fluid"
																	style="width: 100px;display: block;margin: 0 auto;">
																<span class="pt-3">No Requests Found</span>
															</div>
															<div v-if="!ajaxCallinprogress">
																<div class="contact row py-1 pointer"
																	v-for="employeeconnection in employeeDataCount"
																	:key="employeeconnection._id">
																	<div class="avatar-wrapper">
																		<img :src="employeeconnection.company.logo||'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
																			class="avatar"
																			:alt="employeeconnection.company.company_name">
																	</div>
																	<div class="col pl-4 pr-2">
																		<span class="name h6 fs-14">
																			<span
																				class="defaultDarkBlue">{{employeeconnection.company.company_name}}</span><br>
																			invited by <span
																				class="text-secondary">{{employeeconnection.invited_by.full_name}}</span>
																		</span>
																		<p class="last-message text-truncate py-1 mb-0">
																			<a class="btn btn-new-primary btn-smm ml-1 mr-4 mb-1"
																				v-on:click.once="changeEmployeeStatus('active',employeeconnection._id)">Accept</a>
																			<a class="btn btn-new-darkdanger btn-smm mx-4 mb-1"
																				v-on:click.once="changeEmployeeStatus('ignore',employeeconnection._id)">Reject</a>
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="list-submenu">
						<!-- Products Dropdown -->
						<el-dropdown trigger="click" placement="bottom">
							<span class="el-dropdown-link">
								<el-tooltip class="item" effect="dark" content="Products" placement="bottom">
									<div class="pointer row align-items-center no-gutters px-2 btn btn-icon text-white"
										style="left:0px !important;height: 58px;line-height: 40px;box-shadow: none !important;top: -8px;">
											<img src="https://cdn.digicollect.com/cdn/scmnew/images/menus/images/products.svg" style="width:30px;">
									</div>
								</el-tooltip>
							</span>
							<el-dropdown-menu slot="dropdown">
								<div v-if="productsList.length > 0" class="dropdown-apps uk-grid uk-grid-width-medium-1-3 uk-text-center d-flex align-items-center flex-wrap">
									<a v-for="(product,index) in productsList" :key="index" :href="product.product_name == 'DigiAdmin' ? 'javascript:' : product.public_url" :target="product.product_name == 'DigiAdmin' ? '' : '_blank'"
										class="logoblock px-2">
										<div class="product-placement">
											<i v-if="product.product_name == 'Accounting'" class="logo-accounting-bookeeping material-icons md-36 md-color-orange-600"></i>
										</div>
										<div>
											<i v-if="product.product_name == 'Chat'" class="logo-chat material-icons md-36 md-color-light-green-600"></i>
										</div>
										<div>
											<i v-if="product.product_name == 'DigiHRMS'" class="logo-hrms material-icons md-36 md-color-blue-600 pl-2" > </i>
										</div>
										<div>
											<img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/admin.png" v-if="product.product_name == 'DigiAdmin'" class="logo-cloud material-icons md-36 md-color-orange-600 ml-2" style="width: 28px; height: 28px;"/>
										</div>
										<div>
											<i v-if="product.product_name == 'CRM'" class="logo-crm material-icons md-36 md-color-light-green-600"></i>
										</div>
										<div>
											<i v-if="product.product_name == 'DigiPOS'" class="logo-pos material-icons md-36 md-color-blue-600"></i>
										</div>
										<div class="product-placement">
											<i v-if="product.product_name == 'SCM'" class="logo-scm material-icons md-36 md-color-blue-600 pl-2"></i>
										</div>
										<div class="mr-1 product-placement">
											<i v-if="product.product_name == 'Forms'" class="logo-forms material-icons md-36 md-color-cyan-600"></i>
										</div>
										<div class="mr-1">
											<i v-if="product.product_name == 'DigiHelp'" class="logo-help-desk material-icons md-36 md-color-blue-600"></i>
										</div>
										<div class="">
											<i v-if="product.product_name == 'DigiExpense'" class="logo-expence-management material-icons md-36 md-color-blue-600" style="font-size: 22px !important;margin-left: -5px !important;"></i>
										</div>
										<span v-if="product.product_name.includes('Digi')" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">{{product.product_name}}</span>
										<span v-else class="uk-text-muted uk-display-block new-blue mt-2 fw-500">{{'Digi'+product.product_name}}</span>
										<!-- <span v-if="product.product_name == 'Accounting'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiAccounting</span>
										<span v-if="product.product_name == 'DigiAdmin'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiAdmin</span>
										<span v-if="product.product_name == 'Chat'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiChat</span>
										<span v-if="product.product_name == 'DigiHRMS'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiHRMS</span>
										<span v-if="product.product_name == 'CRM'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiCRM</span>
										<span v-if="product.product_name == 'DigiPOS'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiPOS</span>
										<span v-if="product.product_name == 'SCM'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiSCM</span>
										<span v-if="product.product_name == 'Forms'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiForms</span>
										<span v-if="product.product_name == 'DigiHelp'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">DigiHelp</span>
										<span v-if="product.product_name == 'DigiExpense'" class="uk-text-muted uk-display-block new-blue mt-2 fw-500" style="margin-left: 6px !important;">DigiExpense</span> -->
										<hr>
									</a>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div class="list-submenu time-align mt-1">
						<div class="col-auto">
							<div class="row no-gutters align-items-center justify-content-end">
								<el-dropdown trigger="click" placement="bottom">
									<span class="el-dropdown-link">
										<div class="timezone-button products-button pointer">
											<div class="w-100">
												<span class="text-white text-center d-block" v-if="selectedTimeZone==''">
													{{timeDefault}}
												</span>
												<span class="text-white text-center d-block" v-if="selectedTimeZone=='America/New_York'">
													{{timeNewYork}}
												</span>
												<span class="text-white text-center d-block" v-if="selectedTimeZone=='Europe/London'">
													{{timeLondon}}
												</span>
												<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Kolkata'">
													{{timeKolkata}}
												</span>
												<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Singapore'">
													{{timeSingapore}} SGT
												</span>
												<span class="text-white text-center d-block" v-if="selectedTimeZone=='Asia/Yangon'">
													{{timeYangon}} MMT
												</span>
											</div>
										</div>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item >
											<div class="row no-gutters align-items-center flex-nowrap">
												<span class="px-3"
													v-on:click=' guess = false, newyork = true, london=false, kolkata=false, singapore=false, yangon=false, selectedTimeZone = "America/New_York" '>{{timeNewYork}}</span>
											</div>
										</el-dropdown-item>
										<el-dropdown-item >
											<div class="row no-gutters align-items-center flex-nowrap">
												<span class="px-3"
													v-on:click=' guess = false, newyork = false, london=true, kolkata=false, singapore=false, yangon=false, selectedTimeZone = "Europe/London" '>{{timeLondon}}</span>
											</div>
										</el-dropdown-item>
										<el-dropdown-item >
											<div class="row no-gutters align-items-center flex-nowrap">
												<span class="px-3"
													v-on:click=' guess = false, newyork = false, london=false, kolkata=true, singapore=false, yangon=false, selectedTimeZone = "Asia/Kolkata" '>{{timeKolkata}}</span>
											</div>
										</el-dropdown-item>
										<el-dropdown-item >
											<div class="row no-gutters align-items-center flex-nowrap">
												<span class="px-3"
													v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=true, yangon=false, selectedTimeZone = "Asia/Singapore" '>{{timeSingapore}}
													SGT</span>
											</div>
										</el-dropdown-item>
										<el-dropdown-item >
											<div class="row no-gutters align-items-center flex-nowrap">
												<span class="px-3"
													v-on:click=' guess = false, newyork = false, london=false, kolkata=false, singapore=false, yangon=true, selectedTimeZone = "Asia/Yangon" '>{{timeYangon}}
													MMT</span>
											</div>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div class="mt-3 justify-content-center animated" style="display: flex" :class="{'in-down':switch_success == false || switch_success,'d-none':!switch_success,'fadeOut': switch_success == false}" v-if="switch_success">
        	<switch-company-success @hideSuccess="hideSuccess" modal_name="switch-success" :company_success_msg="company_success_msg"></switch-company-success>
		</div>
	</div>
</template>
<script>
	import {
		EventBus
	} from "./eventBus/nav-bar-event.js";
	import globals from "./globals"
	import moment from "moment-timezone";
	import axios from "axios";
	import navbarMixin from "./mixins/navbarMixin.js";
	import notificationMixin from "./mixins/notification.js";
	import {
		User
	} from "./mixins/user.js";
	import {
		EmployeeManage
	} from "./mixins/employees.js";
	import {
		HalfCircleSpinner
	} from 'epic-spinners'
	import organization from './mixins/organization.js';
	import settings from './mixins/settings.js';
	const SwitchCompanySuccess = () => import('./SwitchCompanySuccess');
	import Avatar from 'vue-avatar';
	export default {
		mixins: [navbarMixin, User, EmployeeManage, notificationMixin, organization, settings],
		data() {
			return {
				isp_stable: false,
				minimizeMenu: false,
				toggleSideBar: false,
				switch_success: false,
				comp_active: false,
				togglemenu: true,
				timeDefault: moment()
					.tz(moment.tz.guess())
					.format("LTS z"),
				timeNewYork: moment()
					.tz("America/New_York")
					.format("LTS z"),
				timeLondon: moment()
					.tz("Europe/London")
					.format("LTS z"),
				timeKolkata: moment()
					.tz("Asia/Kolkata")
					.format("LTS z"),
				timeSingapore: moment()
					.tz("Asia/Singapore")
					.format("LTS"),
				timeYangon: moment()
					.tz("Asia/Yangon")
					.format("LTS"),
				timedefault: "",
				guess: true,
				newyork: false,
				london: false,
				kolkata: false,
				singapore: false,
				yangon: false,
				selectedTimeZone: "",
				universalSearch: false,
				seeRequests: false,
				account_details: {},
				search_key: "",
				searched_data: [],
				skip: 0,
				limit: 5,
				comp_limit: 10,
				convert_data: "",
				employeeDataCount: [],
				contactRequestTab: false,
				employeeRequestTab: false,
				ajaxCallinprogress: false,
				productsList: [],
				comp_data: [],
				given_comp_index: 0,
				new_token: "",
				outer_el: 0,
				inner_el: 0,
				user_company:[],
				company_success_msg:'',
				show_hide_comp_switch_dropdown: false,
				count1: 0,
				scroll_flag: false,
				expandMenu:false,
				org_company_list_flag: false,
				org_company_list_loading: false
			};
		},
		components: {
			HalfCircleSpinner,
			SwitchCompanySuccess,
			Avatar
		},
		methods: {
			checkISP(){
				setInterval(() => {
					if(navigator.onLine){
						this.isp_stable = true
					}
					else{
						this.isp_stable = false
					}
				}, 1000);
			},
			expandMenuLeft() {
				// this.expandMenu = true;
				EventBus.$emit("expandLeftMenu");
				// this.expandMenu = true;
			},
			menuBars(i){
				if(i == 'submenu'){
					this.minimizeMenu = !this.minimizeMenu
				}
			},
			showHideCompSwitchDropdown() {
	            this.show_hide_comp_switch_dropdown = !this.show_hide_comp_switch_dropdown;
				if(this.org_company_list_flag == false) {
					this.getOrganizationAndComp();
				}
	        },
	        onBlurHideCompSwitch(e) {
	            if (this.$el.contains(e.target)) {
	                this.show_hide_comp_switch_dropdown = false
	            }
	        },
			setCookie(days) {
				var expires = "";
				if (days) {
					var date = new Date();
					date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
					expires = "; expires=" + date.toUTCString();
				}
				// document.cookie = "Token" + "=" + (this.new_token || "") + expires + "; path=/";
           	    document.cookie = "Token" + "=" + (this.new_token || "") + expires + ";domain=.digicollect.com; path=/";
			},
			hideSuccess() {
				this.switch_success = false;
				this.setCookie(7);
				setTimeout(() => {
                	// axios.defaults.headers.common.Authorization = this.$cookie.get("Token");
					window.location.reload();
				}, 500);
			},
			// Redirection to Request Page
			goToManager() {
				this.$router.push({
					name: 'ManageRequests'
				})
				window.location.reload()
			},
			// Redirection to Notification Page
			goToNotification() {
				this.$router.push({
					name: 'Notification'
				});
				window.location.reload()
			},
			// Redirection to New User Page
			goToAddNewUser() {
				this.$router.push({
					name: 'AddNewUser'
				})
			},

			goToProfile(id) {
				this.$router.push("/PersonalInfoView/" + id);
			},
			// Redirection to Company Profile Page
			gotoCompany(data) {
				this.convert_data = data.$oid
				var convert_data = data.$oid
				this.$router.push("/companyprofile/" + data);
			},
			// Not Used
			addEmployee() {
				//this.$modal.show("add-employee-modal")
				this.$router.push('/addemployee');
			},
			// Search User, Companies and Employees
			searchUser() {
				/*this.$http
					.post("/universal_search", {
						search_key: this.search_key,
						skip: 0,
						limit: 5
					})
					.then(response => {
						if (response.data.status_id == 1) {
							this.searched_data = response.data.response
							// this.userData = response.data.response;
						}
					})
					.catch(error => {});*/
			},
			clearSearchkey() {
				this.search_key = ""
				//this.searchUser()
			},
			toggleBtn1() {
				EventBus.$emit("navBarClick", true);
			},
			select() {
				EventBus.$emit("topBar", true);
			},
			setTimeZone(timezone) {
				this.$store.dispatch("setTimezone", timezone);
			},
			searchCRM: function () {
				this.universalSearch = !this.universalSearch;
			},
			searchComponent: function () {
				this.$router.push({
					path: "/universalsearch/"
				});
				this.universalSearch = false;
			},
			// Switch Account Between Profile and Company and Set Token
			switchAccount(account_id, index) {
				if (account_id != null) {
					this.$http.post("/change_account", {
						account_id: account_id
					}).then(res => {
						if (res.data.status_id == 1) {
							this.$cookie.set("Token", res.data.Authorization, {
								expires: 7,
								domain: "digicollect.com"
							})
							axios.defaults.headers.common.Authorization = this.$cookie.get("Token");
							window.location.reload()
						}
					})
				} else {
					this.$http.get("/change_account").then(res => {
						if (res.data.status_id == 1) {
							this.$cookie.set("Token", res.data.Authorization, {
								expires: 7,
								domain: "digicollect.com"
							})
							axios.defaults.headers.common.Authorization = this.$cookie.get("Token");
							window.location.reload()
						}
					})
				}
			},
			// Check Request For Employee or Personal
			getRequestType() {
				if (this.account) {
					this.requestType = "business"
				} else {
					this.requestType = "personal"
				}
			},

			// Get ALL Request API
			getUserEmployeeRequest() {
				//  EventBus.$emit("searchEmployee", this.searchKey);
				//  EventBus.$emit("status", statusKey);
				this.ajaxCallinprogress = true;
				this.$http
					.post("/employees/user_pending_requests", {
						skip: this.skip,
						limit: this.limit,
						search_key: ""
					})
					.then(res => {
						if (res.data.status_id == 1) {
							this.employeeDataCount = res.data.response;
							this.ajaxCallinprogress = false;
							//EventBus.$emit("activeEmployee",res.data.response);
						}
					});
			},

			// Change Tab View Contact and Employee Request
			changeModelView(viewName) {
				switch (viewName) {
					case 'contactTab':
						this.contactRequestTab = true;
						this.employeeRequestTab = false;
						//this.getConnectionRequestsAPI();
						break;
					case 'employeeTab':
						this.contactRequestTab = false;
						this.employeeRequestTab = true;
						this.getUserEmployeeRequest();
						break;
				}
			},

			// Check Connection Status For Personal and Employee
			getConnectionRequestsAPI() {
				// var request_type = 'personal'
				// if (this.account) {
				// 	request_type = 'employee';
				// }
				//this.getConnectionRequests();
			},

			// Logout and Delete Token
			async logout() {
				await axios.get(globals.AUTH_SERVICE + '/auth/logout').then((resp)=>{
					if(resp.data.status_id == 1) {
						this.$cookie.delete("Token");
						this.$cookie.delete('Token', {
							domain: 'digicollect.com'
						});
						window.location.href = "http://testadmin.digicollect.com/login"
					}
				}).catch((err)=>{
					this.$cookie.delete("Token");
					this.$cookie.delete('Token', {
						domain: 'digicollect.com'
					});
					window.location.href = "http://testadmin.digicollect.com/login"
				})
			},

			// Button Actions for Accept and Reject
			async changeStatus(status, id, sent_by) {
				let params = {
					notification_id: id,
					sent_by: sent_by,
				};
				let res = await this.$http.post(status == 'connected' ? "/connections/accept" : "/connections/reject",
					params);
				if (res.data.status_id == 1) {
					if (status == "disconnected") {
						this.$awn.success("Request Disconnected Successfully")
					}
					if (status == "delete") {
						this.$awn.success("Request Deleted Successfully")
					}
					if (status == "connected") {
						this.$awn.success("Request Accepted Successfully")
					}
					if (status == "ignore") {
						this.$awn.success("Request Rejected Successfully")
					}
					//this.getConnectionRequestsAPI();
				}
			},

			async getProducts() {
				let params = {
					skip: 0,
					limit: 10,
					search_key: ""
				}
				let res = await this.$http.get(globals.AUTH_SERVICE + `/products/list?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}`);
				if (res.data.status_id == 1) {
					this.productsList = res.data.response;
					this.productsList.forEach((product, prod_index)=>{
						if(product.product_name == 'Accounting') {
							delete this.productsList[prod_index]
						}
					})
				}
			},

			goToSettings() {
				this.show_hide_comp_switch_dropdown = false
				this.$router.push('/settings/general');
			},

			compActive(index) {
				// this.given_comp_index = index;
				// this.comp_active = !this.comp_active;
				this.comp_data[index].org_active = !this.comp_data[index].org_active
            	this.$forceUpdate();
			},
			//Api call to get the organization and  company list
			async getOrganizationAndComp() {
				/**@params = skip  @params = limit, @params = search_key*/
				try {
					this.org_company_list_loading = true;
					let param = {
						skip: this.skip,
						limit: this.comp_limit,
						search_key: this.search_key,
					};
					let response = await this.getHierarchyListView(param);
					if(response.status_id == 1) {
						this.comp_data = response.response;
						this.user_company = response.user_company;
						if(this.loggedInUser.user_has == 'organization') {
							this.comp_data.forEach((element, index) => {
								element['org_active'] = false
								element.company_list.forEach((element1, index1) => {
									if(element1.selected) {
										this.outer_el = index;
										this.inner_el = index1;
									}
									return;
								});
								return;
							});
							var element = this.comp_data[this.outer_el];
							var element1 = element.company_list[this.inner_el];
							element.company_list.splice(this.inner_el, 1);
							element.company_list.splice(0, 0, element1);
							this.comp_data.splice(this.outer_el, 1);
							this.comp_data.splice(0, 0, element);
						} else if(this.loggedInUser.user_has == 'company') {
							this.comp_data.forEach((element, index) => {
								if(element.selected) {
									this.outer_el = index;
								}
								return;
							});
							var element = this.comp_data[this.outer_el];
							this.comp_data.splice(this.outer_el, 1);
							this.comp_data.splice(0, 0, element);
						}
						this.count1 = response.response.length;
						this.org_company_list_flag = true;
						this.org_company_list_loading = false;
					}
				}
				catch(err) {
					this.org_company_list_flag = false;
                    this.org_company_list_loading = false;
				}
			},
			async scrollGetOrganizationAndComp(e) {
				if(this.scroll_flag == false) {
					const {target} = e;
					if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
						//this code will run when the user scrolls to the bottom of this div so
						//you could do an api call here to implement lazy loading
						try {
							let param = {
								skip: this.count1,
								limit: this.comp_limit,
								search_key: this.search_key,
							};
							let response = await this.getHierarchyListView(param);
							if(response.status_id == 1) {
								if (response.skip == this.count1) {
									let tempFiles = response.response
									this.scroll_flag = response.response.length ? false : true
									tempFiles.forEach(el => {
										this.comp_data.push(el)
									})
									if(this.loggedInUser.user_has == 'organization') {
										this.comp_data.forEach((element, index) => {
											if(element.hasOwnProperty('org_active')) {
												if(element.org_active == false) {
													element['org_active'] = false
												} else {
													element['org_active'] = true
												}
											} else {
												element['org_active'] = false
											}
											element.company_list.forEach((element1, index1) => {
												if(element1.selected) {
													this.outer_el = index;
													this.inner_el = index1;
												}
												return;
											});
											return;
										});
										var element = this.comp_data[this.outer_el];
										var element1 = element.company_list[this.inner_el];
										element.company_list.splice(this.inner_el, 1);
										element.company_list.splice(0, 0, element1);
										this.comp_data.splice(this.outer_el, 1);
										this.comp_data.splice(0, 0, element);
									} else if(this.loggedInUser.user_has == 'company') {
										this.comp_data.forEach((element, index) => {
											if(element.selected) {
												this.outer_el = index;
											}
											return;
										});
										var element = this.comp_data[this.outer_el];
										this.comp_data.splice(this.outer_el, 1);
										this.comp_data.splice(0, 0, element);
									}
									this.count1 += response.response.length;
								}
							}
						} catch (reason) {

						}
					}
				}
			},
			//Api call to switch the company or organisation  in given list
			/**@params = comp_id */
			async switchNewCompany(comp_id) {
				try {
					let params = {
						company_id: comp_id
					}
					let response = await this.switchCompany(params);
					if(response.token) {
						this.new_token = response.token;
						this.$cookie.delete("Token");
						this.$cookie.delete('Token', {
							domain: 'digicollect.com'
						});
						this.show_hide_comp_switch_dropdown = false
						this.switch_success = true;
						setTimeout(() => {
            				this.$modal.show('switch-success');
						}, 500);
						this.company_success_msg = response.message
					}
				}
				catch(err) {
				}
			}
		},
		mounted() {
			this.checkISP();
			// this.getOrganizationAndComp();
			setInterval(() => {
					(this.timeDefault = moment()
						.tz(moment.tz.guess())
						.format("HH:mm:ss z")),
					(this.timeNewYork = moment()
						.tz("America/New_York")
						.format("HH:mm:ss z")),
					(this.timeLondon = moment()
						.tz("Europe/London")
						.format("HH:mm:ss z")),
					(this.timeKolkata = moment()
						.tz("Asia/Kolkata")
						.format("HH:mm:ss z")),
					(this.timeSingapore = moment()
						.tz("Asia/Singapore")
						.format("HH:mm:ss")),
					(this.timeYangon = moment()
						.tz("Asia/Yangon")
						.format("HH:mm:ss"));
				}, 1000),
				EventBus.$on("navBarClick", toggle => {
					this.isHidden = !this.isHidden;
					this.togglemenu = !this.togglemenu;
				});
			EventBus.$on("updateNotification", () => {
				//this.getNotificationRequests();
			});
			this.getProducts();
			setTimeout(() => {
				EventBus.$on('update_product_list_api', ()=> {
					this.getProducts();
				})
			}, 1000);
		},
		computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser;
			},
			userAccounts() {
				return this.$store.state.userAccounts;
			},
			isBusinessAccount() {
				return this.$store.state.isBusinessAccount;
			},
			account() {
				return this.$store.state.account;
			},
			selectedCompany() {
				return this.$store.state.selectedCompany;
			}
		},
		created() {
			this.getRequestType();
			this.searchUser();
			//this.getConnectionRequestsAPI();
			//this.getUserEmployeeRequest();
			this.changeModelView('contactTab');
			//this.getNotificationRequests();
		},
		beforeDestroy() {
			EventBus.$off('update_product_list_api')
		}
	};
</script>

<style>
	@media only screen and (max-width: 500px){
        .background{
			background-color: #00448b;
			width: 100%;
			padding: 5px 0px !important;
			user-select: none;
		}
        
    }
    .background{
        background-color: #00448b;
        width: 100%;
        padding: 5px 15px;
        user-select: none;
    }
	.logut-dropdown-new {
		max-height: 250px;
		overflow: auto;
	}

	.show-message-time {
		white-space: pre-line;
		width: 67px;
		text-align: center;
		margin-top: -10px;
	}

	.nav-tabs.nav-topbar-tab .nav-link.active:not(.dropdown-toggle):after,
	.nav-tabs.nav-topbar-tab .nav-item.show .nav-link:not(.dropdown-toggle):after {
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;
		background-color: #00448b;
	}

	/* #toolbar {
  height: 58px !important;
  min-height: 58px !important;
} */
	#toolbar {
		height: 58px !important;
		min-height: 58px !important;
		margin-top: 10.5px;
	}

	#toolbar {
		-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
			0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
			0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
	}

	#toolbar .toggle-aside-button,
	#toolbar .search-button,
	#toolbar .quick-panel-button {
		height: 50px !important;
		width: 56px !important;
		padding-right: 0px !important;
	}

	#toolbar .user-menu-button>#dropdownUserMenu {
		height: -webkit-fill-available !important;
		padding-right: 24px !important;
	}

	.app-title {
		padding-left: 0px !important;
		margin-left: 0px !important;
	}

	i.material-icons.md-36 {
		font-size: 22px !important;
		line-height: 36px;
		height: 36px;
		width: 36px;
		padding-bottom: 15px;
		color:#00448b !important;
	}

	.app-title.menu-btn-hide {
		padding-left: 15px !important;
	}

	.bg-secondary-light {
		background-image: linear-gradient(to right,
				#0d84f2,
				#0d84f2 51%,
				#69b3fc,
				#9fcbff) !important;
	}

	.center-logo.navbar-header {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		line-height: 40px;
		text-align: center !important;
		margin-top: 1px;
		height: 58px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.center-logo.navbar-header .navbar-branddigi {
		margin-right: 0px;
		padding-bottom: 0px;
		padding-top: 0px;
		color: rgba(255, 255, 255, 1);
		text-decoration: none !important;
	}

	@media screen and (min-width: 400px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem !important;
		}
	}

	@media screen and (min-width: 648px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem !important;
		}
	}

	@media screen and (max-width: 1025px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem;
		}
	}

	@media (max-width: 1060px) {
		.language-button {
			display: none !important;
		}
	}

	.center-logo.navbar-header .navbar-branddigi span.navlogo {
		font-weight: 600;
		padding: 0rem 0.5rem 0rem 0.3rem;
		text-transform: none;
		border-color: transparent;
		display: inline-block;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		box-sizing: border-box;
		-webkit-appearance: none;
		outline: 0;
		color: rgba(255, 255, 255, 1);
		font-size: 18px;
	}

	.center-logo.navbar-header .navbar-branddigi span.dark-logo {
		font-weight: 600;
		padding: 0.8rem 0.4rem 0.8rem 0rem;
		text-transform: none;
		border-color: transparent;
		display: inline-block;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		box-sizing: border-box;
		-webkit-appearance: none;
		outline: 0;
		color: #fff !important;
		font-size: 18px;
	}

	@media (min-width: 769px) {
		.navbar-header .navbar-branddigi {
			padding-top: 0px;
		}
	}

	.navbar-header .navbar-branddigi i {
		display: inline-block;
		color: rgba(255, 255, 255, 1);
		font-size: 20px;
	}

	.timezone-button .dropdown-toggle::after {
		color: #fff;
	}

	.flag {
		height: 32px;
		width: 32px;
		line-height: 20px;
		margin-right: 10px;
	}

	#contenttime {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		transition: 0.5s;
		text-align: center;
	}

	#contenttime .wrappertime {
		margin-right: 10px;
	}

	#contenttime span {
		transition: 0.2s;
		font-size: 16px;
		margin: 0;
		font-weight: 600;
	}

	#contenttime p {
		transition: 0.2s;
	}

	.faded {
		opacity: 0;
		transition: 0.2s;
	}

	.timezone-button {
		line-height: 100%;
		margin-left: 8px;
	}

	.clock {
		font-size: 24px;
		padding-right: 20px;
		color: #fff;
	}

	.uk-margin-top {
		height: 80px;
	}

	.dropdown-toggle.products-button::after {
		content: none !important;
	}

	@media (min-width: 576px) {
		.px-sm-32 {
			padding-left: 3.2rem !important;
		}
	}

	.app-title {
		font-size: 20px;
		font-weight: 600;
		/* padding: 0rem 3.4rem 0.8rem 0.3rem; */
		text-transform: none;
		min-width: initial;
		height: auto;
		line-height: normal;
		cursor: pointer;
		border-radius: 100% !important;
		background-color: transparent;
		border-color: transparent;
		text-decoration: none !important;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border: 0px solid transparent;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		letter-spacing: 0.04em;
		-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
			0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
			0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
		-webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		-o-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
			-webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		will-change: box-shadow;
		position: relative;
		outline: none;
		color: #fff !important;
	}

	div.content-app {
		padding-left: 15px;
		padding-right: 15px;
	}

	.base-box.boxed,
	.base-box.boxed-inverse,
	.boxed-inverse,
	[class*="col-md-"].boxed,
	[class*="col-md-"].boxed-inverse,
	.grid-box>.grid-item.boxed-inverse,
	.row.vertical-row>div.boxed-inverse,
	.row.vertical-row>div.boxed {
		border-style: none !important;
	}

	.shadow-1,
	.adv-img-down-text.shadow-1 .img-box,
	.two-blocks-container.shadow-1>.row,
	.section-bg-color .advs-box.boxed-inverse,
	.section-bg-color .adv-img.boxed-inverse {
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.13) !important;
	}

	.black {
		color: #212121 !important;
	}

	.col-center {
		float: none !important;
		margin-left: auto !important;
		margin-right: auto !important;
		clear: both;
		padding-top: 20px;
	}

	.section-bg-image .advs-box.boxed-inverse,
	.black .flex-control-paging li a:hover,
	.adv-img-down-text.boxed-inverse:hover,
	.advs-box-multiple div.circle,
	.list-blog input,
	.list-blog select,
	.section-bg-image [class*="col-md-"].boxed-inverse,
	[class*="shadow-"].boxed-inverse,
	.sidebar-nav ul ul,
	.base-box.boxed,
	.base-box.boxed-inverse,
	.advs-box.boxed-inverse,
	.section-bg-color .form-box .form-control,
	.boxed-inverse:not(.shadow-1) .form-box,
	.boxed-inverse:not(.shadow-1) .form-box .form-control,
	.section-two-blocks .form-box .form-control,
	.section-bg-color .advs-box.boxed-inverse {
		background-color: #fff;
	}

	hr.space {
		border: none !important;
		clear: both;
		height: 70px;
		margin: 0;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}

	.text-l {
		line-height: 38px;
	}

	.text-l {
		font-size: 27px !important;
		line-height: 31px;
	}

	.grid-list {
		position: relative;
	}

	.grid-box.row {
		margin: -20px;
		width: calc(100% + 40px);
	}

	.grid-box>.grid-item {
		padding: 20px;
	}

	.app-box.app-box-top-bottom {
		display: block;
	}

	.grid-item>div {
		padding-left: 0;
		padding-right: 0;
	}

	.app-box-top-bottom .app-box-cell {
		display: block;
		text-align: center;
	}

	.app-box-cell {
		vertical-align: middle;
	}

	.app-box.app-box-top-bottom .app-box-cell>img {
		margin: 0px 0 15px 0;
	}

	.app-box.app-box-top-bottom .app-box-cell>img {
		float: none;
		margin: 7px 0 7px 0;
		display: inline-block;
	}

	.app-box-cell img.text-xl {
		width: 55px;
	}

	.app-box.app-box-top-bottom .app-box-cell>i {
		float: none;
		margin: 7px 0 7px 0;
		display: inline-block;
	}

	.app-box.app-box-top-bottom .app-box-cell>i {
		margin: 0px 0 15px 0;
		color: #5584ff;
	}

	.app-box-cell i.text-xl {
		width: 55px;
		font-size: 55px;
		height: 55px;
		line-height: 55px;
	}

	.app-box-top-bottom .app-box-cell label {
		margin-bottom: 10px;
	}

	.app-box-top-bottom .app-box-cell label {
		padding: 0;
	}

	.app-box label {
		font-weight: 600;
	}

	.app-box-top-bottom .app-box-cell p {
		padding: 0;
		margin-bottom: 0;
		margin: 0;
	}

	.grid-item p {
		height: auto;
	}

	.text-s {
		line-height: 20px;
		font-size: 13px !important;
	}

	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper,
	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper>.aside-content {
		width: 0rem !important;
		min-width: 0rem !important;
	}

	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside {
		width: 0rem;
	}

	.layout.layout-vertical.fuse-aside-folded.layout-left-navigation .wrapper {
		padding-left: 0rem;
	}

	/* .side-menu {
		  width: 281px;
		  position: fixed;
		  top: 0;
		  left: 0;
		  background: #fff;
		  height: 100%;
		  border-right: solid 1px #c5d6de;
		  overflow: auto;
		  z-index: 60;
		  padding: 90px 0 110px;
		  z-index: 1000;
		  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
		  transition-property: width, min-width, max-width, -webkit-transform;
		  transition-property: transform, width, min-width, max-width;
		  transition-property: transform, width, min-width, max-width, -webkit-transform;
		  -webkit-transition-duration: 150ms;
		  transition-duration: 150ms;
		  -webkit-transition-timing-function: ease-in-out;
		  transition-timing-function: ease-in-out;
		  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
		  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
		} */
	/* body.sidebar-hidden .side-menu {
		  display: none;
		} */
	.layout>.aside>.aside-content-wrapper>.aside-content {
		width: 280px;
	}

	.layout>.aside>.aside-content-wrapper {
		width: 280px;
		min-width: 280px;
	}

	.nav-link {
		text-decoration: none;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 48px;
		padding: 0 24px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		font-size: 14px;
	}

	.layout>.aside>.aside-content-wrapper>.aside-content>#sidenav .nav-link {
		color: #212121;
	}

	.nav-link i,
	.nav-link [class^="icon-"],
	.nav-link [class*=" icon-"] {
		font-size: 24px !important;
		margin-right: 1.9rem;
	}

	.dropdown-toggle.dropdownhideafter::after {
		content: none !important;
	}

	.dropdown-toggle.text-white::after {
		color: #fff !important;
	}

	.dropdown-apps {
		width: 220px;
		min-height: 100px;
		max-height: 400px;
		top: 16px !important;
		left: -15px !important;
	}

	.dropdown-menu {
		-webkit-border-radius: 8px !important;
		border-radius: 8px !important;
		border: 1px solid #cdd2da !important;
		-webkit-box-shadow: 3px 2px 20px rgba(0, 0, 0, .2) !important;
		box-shadow: 3px 2px 20px rgba(0, 0, 0, .2) !important;
	}

	.dropdown-time {
		top: 11px !important;
	}

	.dropdown-time.show {
		left: -35px !important;
	}

	.dropdown-search {
		width: 420px;
		min-height: 100px;
		max-height: 390px;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 10px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -9px !important;
		z-index: 99;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	.dropdown-requests {
		width: 420px;
		min-height: 100px;
		max-height: 390px;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 10px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -9px !important;
		z-index: 99;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	.dropdown-profiles {
		width: 400px !important;
		min-height: 100px;
		max-height: 490px !important;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 16px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -10px !important;
		z-index: 2;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	@media screen and (max-width: 429px) {
		.dropdown-profiles {
			width: 350px !important;
			margin: 0.25rem 13rem 0 !important;
		}
	}

	.uk-margin-bottom {
		margin-bottom: 16px !important;
	}

	.uk-grid:after,
	.uk-grid:before {
		content: "";
		display: block;
		overflow: hidden;
	}

	[class*="uk-grid-width"]>* {
		box-sizing: border-box;
		width: 100%;
	}

	.uk-grid-width-medium-1-3>* {
		width: 30%;
	}

	.uk-margin-top {
		margin-top: 15px !important;
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	@media (min-width: 1220px) {
		.uk-grid>* {
			padding-left: 0px;
		}
	}

	.uk-grid>* {
		-webkit-box-flex: 0;
		flex: none;
		margin: 0;
		width: 100%;
		padding: 5px 10px 7px 17px;
		border-bottom: solid 1px #cce3fc;
		float: left;
		text-align: center;
		text-decoration: none;
		display: flex !important;
		line-height: 22px;
	}

	.uk-grid>*:hover {
		text-decoration: none;
		background: #cce3fc !important;
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	i.dg-icons.md-36 {
		font-size: 36px !important;
		vertical-align: -36px;
		line-height: 36px;
		height: 36px;
		width: 36px;
		padding-bottom: 15px;
		color: #00448b !important;
	}

	.uk-grid>*> :last-child {
		margin-bottom: 0;
	}
	.uk-grid > *:last-child {
		border-bottom: solid 0px transparent;
	}
	.uk-text-muted {
		color: #212121 !important;
	}

	.uk-display-block {
		display: block !important;
	}

	.logoblock .uk-display-block {
		margin-top: 8px;
		font-size: 13px;
	}

	.products-button {
		height: 3.4rem !important;
		line-height: 3.4rem !important;
		border-radius: 100px !important;
		border: 1px solid rgb(184, 223, 255) !important;
		padding: 0px 17.3px !important;
		text-transform: capitalize !important;
		width: 140px;
	}

	.products-button div span {
		font-size: 14px;
		font-weight: 800;
	}

	.md-theme-default a:not(.md-button):hover {
		color: inherit;
	}

	.md-theme-default a:not(.md-button) {
		color: inherit;
	}

	/* @media only screen and (max-width: 897px) {
  .app-title.menu-btn-hide {
    padding-left: 0px !important;
  }
} */
	@media (min-width: 899px) {
		.hidden-md-upp {
			display: none !important;
		}
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	.searchContent {
		background-color: #0d5be6;
		color: white;
		border-radius: 50%;
	}

	.searchFilter {
		width: 7.3px;
		height: 10.7px;
	}

	.searchProducts {
		width: 375px;
		height: 34px;
		border-radius: 100px !important;
		border: solid 1px #d5d5d5 !important;
		padding-left: 35px;
		padding-right: 35px;
		background-image: linear-gradient(to bottom,
				#efefef,
				#ffffff 25%,
				#ffffff 48%,
				#ffffff 78%,
				#efefef);
	}

	.searchIcon {
		color: #cfcfcf !important;
		position: absolute;
		pointer-events: cursor;
		top: 22px;
		font-size: 19px;
		left: 15px;
		display: inline-block;
	}

	.closeIcon {
		color: #cdd2da !important;
		position: absolute;
		pointer-events: cursor;
		top: 22px;
		font-size: 19px;
		right: 10px;
		display: inline-block;
	}

	.searchTitle {
		top: 0px;
		position: relative;
	}

	.productSearch {
		overflow-y: auto;
		min-height: 100px;
		max-height: 300px;
		overflow-x: hidden;
	}

	.notifySearch {
		overflow-y: auto;
		min-height: 190px;
		overflow-x: hidden;
		max-height: 238px;
	}

	.profileSearch {
		overflow-y: auto;
		min-height: 100px;
		overflow-x: hidden;
		max-height: 240px;
	}

	.notifySearch .contact,
	.productSearch .contact {
		min-height: 60px;
	}

	.notifySearch .contact.unread {
		background-color: rgba(232, 232, 232, 0.76);
	}

	.notifySearch .contact:last-child {
		margin-bottom: 30px;
	}

	.productSearch .contact:last-child {
		margin-bottom: 40px;
	}

	/* .profileSearch.activatedsearch {
  overflow-y: auto;
  height: 210px;
  overflow-x: hidden;
} */
	.searchProductContent {
		height: 21px;
		width: 14px;
	}

	.avatar.avatar-grey {
		background-color: #cdd2da;
		font-size: 16px !important;
		font-weight: 600 !important;
		letter-spacing: 0.28px;
		color: #ffffff;
		width: 40px !important;
		min-width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
	}

	.input-search-rounded-box {
		position: relative;
	}

	.input-search-rounded-box .input-search-close,
	.input-search-rounded-box .input-search-icon {
		position: absolute;
		top: 50%;
		z-index: 1;
		width: 2.573rem;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.input-search-rounded-box .input-search-icon {
		left: 8px;
		font-size: 16px;
		color: #9e9e9e;
		text-align: center;
		pointer-events: none;
	}

	.input-search-rounded-box .input-search-icon+.form-control {
		padding-left: 38px !important;
	}

	.input-search-rounded-box .form-control {
		border-radius: 200px !important;
	}

	.input-search-close.icon {
		font-size: inherit !important;
		line-height: inherit !important;
	}

	button.input-search-close {
		-webkit-appearance: none !important;
		padding: 0 !important;
		cursor: pointer !important;
		background: 0 0 !important;
		border: 0 !important;
	}

	.input-search-rounded-box .input-search-close {
		right: 8px !important;
		color: #cdd2da !important;
		font-size: 19px !important;
		width: 18px !important;
		height: 18px !important;
		line-height: 18px !important;
	}

	.input-search-rounded-box .form-control {
		height: 3.573rem !important;
		-webkit-appearance: none !important;
		border-color: #e0e0e0 !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		-webkit-transition: -webkit-box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		-o-transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		-moz-appearance: none !important;
	}

	.input-search-rounded-box .form-control {
		display: block !important;
		width: 100% !important;
		padding: .429rem 1.072rem !important;
		font-size: 14px !important;
		line-height: 1.571429 !important;
		color: #4e5764 !important;
		background-color: #fff !important;
		-webkit-background-clip: padding-box !important;
		background-clip: padding-box;
		border: solid 1px #d5d5d5 !important;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#efefef), color-stop(25%, #ffffff), color-stop(48%, #ffffff), color-stop(78%, #ffffff), to(#efefef)) !important;
		background-image: linear-gradient(to bottom, #efefef, #ffffff 25%, #ffffff 48%, #ffffff 78%, #efefef) !important;
	}

	.searchfooter {
		height: 40px;
		background-color: #f1f8fc;
		line-height: 40px;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.51px;
		color: #fff;
		display: block;
		position: absolute;
		bottom: 0px;
		width: 100%;
	}

	.searchfooter:hover {
		color: #fff !important;
	}

	.notifyheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: 40px;
		height: 100%;
		position: relative;
		background: #f1f8fc;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.notifyheader>.descriptionheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 16px;
		border-bottom: 1px solid #cdd2da;
	}

	.notifyheader>.descriptionheader .description-text-header-left h5 {
		font-size: 14px;
		font-weight: 600;
		margin: 0;
		color: #808495;
	}

	.notifyheader>.descriptionheader .description-text-header-right {
		font-size: 13px;
		margin-left: auto;
		padding-left: 12px;
		color: #00448b;
		font-weight: 500;
	}

	.notifyheader>.descriptionheader .description-text-header-right {
		display: block;
	}

	.profileheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: 40px;
		position: relative;
		background: #FFFFFF;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.profileheader>.descriptionheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 6px 10px;
		border-bottom: 1px solid transparent;
	}

	.profileheader>.descriptionheader .description-text-header-left h5 {
		font-size: 13px;
		font-weight: 600;
		margin: 0;
	}

	.profileheader>.descriptionheader .description-text-header-right {
		font-size: 13px;
		margin-left: auto;
		padding-left: 12px;
		color: #00448b;
		font-weight: 500;
	}

	.profileheader>.descriptionheader .description-text-header-right {
		display: block;
	}

	/* .profileheader>.descriptionheader .description-text-header-right span {
    width: 26px !important;
    height: 26px;
    line-height: 28px;
    background: #f5f6f9;
    border-radius: 50%;
    text-align: center;
    display: block;
	cursor: pointer;
} */
	.profileheader>.descriptionheader .description-text-header-right span {
		background: transparent;
		border-radius: none;
		text-align: center;
		display: block;
		cursor: pointer;
	}

	.profileheader>.descriptionheader .description-text-header-right span i {
		font-size: 17px;
		width: 17px !important;
		height: 17px;
		line-height: 17px;
		color: #00448b;
	}

	.dropdown-menu-header {
		position: relative;
		padding: 20px 20px;
		background-color: #fff;
		border-bottom: 1px solid #e0e0e0;
	}

	.dropdown-menu-header>h5 {
		margin: 0;
	}

	.dropdown-menu-header .badge {
		position: absolute;
		top: 50%;
		right: 20px;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.countnotification {
		position: absolute;
		bottom: 3.3rem;
		right: 0.4rem;
		color: #fff !important;
		background: #ff9a06;
		width: 16px;
		height: 16px;
		line-height: 16px;
		border-radius: 50%;
		font-size: 10px;
		font-weight: 600;
	}

	.list-group.profile-group .list-group-item.three-line {
		min-height: 6.8rem;
		border-bottom: 1px solid #cdd2da;
	}

	.list-group.profile-group .list-group-item.three-line:last-child {
		border-bottom: 1px solid transparent;
		margin-bottom: 25px;
	}

	.list-group.profile-group .list-group-item:hover,
	.list-group.profile-group .list-group-item:focus {
		z-index: 0;
		text-decoration: none;
	}

	.list-group.profile-group .list-group-item h3 {

		font-size: 14px !important;
		font-weight: 600 !important;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #303031 !important;
		margin: 0;
	}

	.list-group.profile-group .list-group-item h4 {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #4e5764 !important;
	}

	.list-group.profile-group .list-group-item p.compname {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #00448b !important;
	}

	.profile-selected.no-accounts {
		position: relative;
		margin-bottom: 44px;
	}

	.profile-selected .list-group .list-group-item h3 {
		font-size: 12px !important;
		font-weight: 600 !important;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #303031 !important;
		margin: 0;
	}

	.profile-selected .list-group .list-group-item p {
		font-size: 12px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #4e5764 !important;
		padding-top: 7px;
	}

	.btn-personal {
		height: 28px !important;
		padding: 0 12px !important;
		border-radius: none !important;
		font-size: 13px !important;
		line-height: 28px !important;
		text-transform: none !important;
		font-weight: 600 !important;
		text-decoration: none !important;
	}

	.btn-logout {
		height: 28px !important;
		padding: 0 18px !important;
		border-radius: 18px !important;
		font-size: 12px !important;
		line-height: 28px !important;
		text-transform: none !important;
		font-weight: 600 !important;
	}

	.profilefooter {
		height: 45px;
		box-shadow: none;
		background-color: #f1f8fc;
		line-height: 45px;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.51px;
		color: #4e5764;
		display: block;
		position: absolute;
		bottom: 0px;
		width: 100%;
	}

	.profilefooter:hover {
		color: #00448b;
	}

	.grey-border-box {
		border: dashed 1px #cdd2da;
		padding: 11px 20px;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.92;
		letter-spacing: 0.56px;
		text-align: center;
		color: #9e9e9e;
		width: 265px;
		margin: 0 auto;
		border-radius: 12px;
	}

	.search-user-company-blocks {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
	}

	.productSearch .contact .search-user-company-blocks .mailtext,
	.productSearch .contact .search-user-company-blocks .user-company-name {
		width: 190px;
	}

	.nav-tabs .nav-link.subnav-tabs {
		color: #303031;
		background-color: transparent;
		border-bottom: 1.5px solid #cdd2da;
	}

	.nav-tabs .nav-link.subnav-tabs.active {
		color: #00448b;
		background-color: transparent;
		border-bottom: 1.5px solid #00448b;
	}

	@media only screen and (max-width: 874px) {
		.dropdown-submenu .dropdown-menu {
			top: 6px !important;
			left: 34% !important;
		}
	}

	@media only screen and (max-width: 939px) {
		.dropdown-submenu .dropdown-menu {
			left: 95px !important;
		}
	}
	.settings-icon {
		top:58px;
		right:29px;
		padding: 4.1px 4px 4.1px 4.3px;
		border-radius: 2px;
		/* border: solid 0.5px #00448b;
		background-color: #00448b; */
	}
	.switch-user-prpfile {
		border-radius: 50%;
		border: 2px solid #ecf1f9;
		width: 75px;
		height: 75px;
	}
	.company-list {
		border: solid 0.2px rgba(128, 132, 149, 0.05);
		background-color: rgba(13, 91, 230, 0.05);
	}
	.overflow-height {
		max-height: 335px;
		min-height: auto;
		overflow-y: auto;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
	.admin-width{
		width: 450px;
		max-height: 528px;
		border-radius: 10px !important;
		background-color: #f3f6fb !important;
		box-shadow: 0 2px 0px 0 rgb(0 0 0 / 10%);
		position: absolute;
		right: 8px;
		left: auto;
		top: 100%;
		margin-top: 5px;
		z-index: 11;
		color: #000000;
		padding: 10px 0;
	}
	.dropdown-width-responsive{
		background-color: #fff;
		border-radius: 10px;
		max-height: 472px;
		margin-left: 15px !important;
		margin-right: 15px !important;
		padding-bottom: 10px;
	}
	.list-dropdown {
	height: 50px;
	padding: 21px 15px 20.9px 13px;
	border-radius: 0px;
	border: solid 0.3px #ebebeb !important;
	background-color: #faf8f8;
	margin-top: -2px;
	}
	.main-dropdown {
	border-radius: 5px;
	border: solid 1px #ebebeb;
	background-color: #f7f7f7;
	height: 50px;
	margin-bottom: -7px;
	margin-top: 0px;
	}
	.main-dropdown.br-t-r-5 {
		border-radius: 5px 5px 0px 0px;
	}
	.main-dropdown.subcompany-dropdown {
		height: 58px;
	}
	.setting-align{
		width: 28px;
		margin-top: -65px;
		margin-left: 352px;
		cursor: pointer;
	}
	.btn-size{
		width: 110px;
		padding: 0 10px !important;
		margin-left: 280px !important;
		margin-top: -36px !important;
	}
	.btn-secondary{
		border-radius: 12px !important;
	}
	.background-default{
		background-color: #fff;
		border: solid 1px #fff !important;
	}
	.open-dropdown{
		border-radius: 5px 5px 0 0;
		border: solid 0.3px #ebebeb;
		height: 50px;
		margin-bottom: -7px;
		margin-top: 5px;
		background-color: #fff;
	}
	.avatar {
		width: 3rem;
		min-width: 3rem;
		height: 3rem;
		line-height: 4rem;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		font-size: 1.7rem;
		font-weight: 500;
		text-align: center;
		color: #FFFFFF;
		background-color: #fff;
	}
	.green-active-org {
		background-color: #008b29;
	}
	.navbar-avatar {
		border-radius: 50% !important;
	}
	.org-icon-rotate-transition {
		font-size: 24px;
		width: 24px;
		height: 24px;
		line-height: 24px;
	}
	.org-icon-rotate-transition {
		line-height: 20px;
	}
	.org-icon-rotate-down.org-icon-rotate-transition {
		transition: all 0.3s;
	}
	.org-icon-rotate-down {
		transform: rotate(180deg);
	}
	.hide-on-mob-screen {
		display: block
	}
	.show-on-mob-screen {
		display: none;
	}
	.switch-company-backdrop {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		background-color: rgba(0, 0, 0, 0.16);
	}
	.navbar-avatar { font-family: 'Poppins', sans-serif !important;}
	@media screen and (max-width: 450px) {
		.admin-width {
			position: fixed;
			top: 50px;
			right: 0;
			left: 0;
			width: 100%;
		}
		.hide-on-mob-screen {
			display: none;
		}
		.show-on-mob-screen {
			display: block;
		}
		.admin-width {
			max-height: 580px;
		}
		.dropdown-width-responsive {
			max-height: 530px;
		}
	}
	.logout-btn-color{
		background-color: #00448B !important;
	}
	@media only screen and (min-width: 1151px){
		.minimize-menu{
			display: none;
		}
		.align{
			display: none;
		}
		.user-menu-align{
			display: none;
		}
		.digi-logo{
			display: none;
		}
	}
	@media only screen and (min-width: 0px) and (max-width: 350px){
		.img-logo{
			margin-top: 20px !important;
		}
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.hidden-sm-down{
			display: none !important;
		}
		.dark-logo{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			/* margin-left: 30px; */
		}
		.user-menu-align{
			text-align: right;
			margin-left: 39%;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 300px;
			min-width: 300px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:351px) and (max-width: 400px){
		.img-logo{
			margin-top: 20px !important;
		}
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.hidden-sm-down{
			display: none !important;
		}
		.dark-logo{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			margin-left: 30px;
			z-index: 999;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 48% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 360px;
			min-width: 360px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:401px) and (max-width: 567px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 53% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 380px;
			min-width: 380px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:568px) and (max-width: 599px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 67% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 420px;
			min-width: 420px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
		
	}
	@media only screen and (min-width:600px) and (max-width: 620px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 64% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 420px;
			min-width: 420px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:621px) and (max-width: 668px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 70% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 420px;
			min-width: 420px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:669px) and (max-width: 768px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 73% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -38% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 420px;
			min-width: 420px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	@media only screen and (min-width:769px) and (max-width: 1150px){
		.avatar{
			margin-left: 10px !important;
		}
		.isp-active {
			width: 40px !important;
			border-radius: 7px;
			padding-left: 9px !important;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-right: 5px;
			font-size: 16px;
			background: #25bb83;
			border: solid 1px #cdd2da;
			font-weight: 500;
			margin-top: 6px !important;
		}
		.isp-button{
			display: none !important;
		}
		.minimize-menu{
			font-size: 12px;
			color: #fff;
			z-index: 999;
			margin-left: 30px;
		}
		.user-menu-align{
			text-align: right;
			margin-left: 73% !important;
		}
		.notification{
			display: none;
		}
		.products{
			display: none;
		}
		.time{
			display: none;
		}
		.time-align{
			margin-left: -10px;
		}
		.submenu-align{
			margin-left: -28% !important;
			margin-top: 5px;
			margin-bottom: -15px !important;
		}
		.notificn{
			margin-top: -8px !important;
		}
		.background-dots {
			background-color: #005dae;
			width: 100%;
			visibility: 999;
			border-radius: 50%;
		}
		.dots-vertical{
			color: #fff !important;
			padding-left: 2px !important;
			padding-right: 2px !important;
			padding-top: 5px;
			padding-bottom: 4px;
		}
		.logo-digi{
			display: none;
		}
		.menu{
			margin-right: -10px !important;
		}
		.notification-dropdown{
			width: 420px;
			min-width: 420px;
		}
		.products-button {
			height: 30px !important;
			line-height: 30px !important;
			border-radius: 100px !important;
			border: 1px solid rgb(184, 223, 255) !important;
			padding: 0px 5.3px !important;
			text-transform: capitalize !important;
			width: 102px;
			margin-left: 0px;
		}
		.nav-bar-digi{
			display: none;
		}
	}
	.box-shadow{
		box-shadow: none !important;
	}
	.isp-active{
		width: 50px;
		border-radius: 7px;
		padding-left: 14px;
		padding-top: 2px;
		padding-bottom: 2px;
		padding-right: 5px;
		font-size: 16px;
		background: #25bb83;
		border: solid 1px #cdd2da;
		font-weight: 500;
	}
	@media screen and (max-width: 316px) {
		.isp-active {
			display: none;
		}
	}
</style>